.visibility-hidden { visibility: hidden; }
.bold { font-weight: bold !important; }

.bg-aqua    { background-color: $aqua    !important; }
.bg-blue    { background-color: $blue    !important; }
.bg-navy    { background-color: $navy    !important; }
.bg-teal    { background-color: $teal    !important; }
.bg-green   { background-color: $green   !important; }
.bg-olive   { background-color: $olive   !important; }
.bg-lime    { background-color: $lime    !important; }
.bg-yellow  { background-color: $yellow  !important; }
.bg-orange  { background-color: $orange  !important; }
.bg-red     { background-color: $red     !important; }
.bg-fuchsia { background-color: $fuchsia !important; }
.bg-purple  { background-color: $purple  !important; }
.bg-maroon  { background-color: $maroon  !important; }

.bg-red-alt     { background-color: $red-alt     !important; }
.bg-pink        { background-color: $pink        !important; }
.bg-purple-alt  { background-color: $purple-alt  !important; }
.bg-deep-purple { background-color: $deep-purple !important; }
.bg-indigo      { background-color: $indigo      !important; }
.bg-blue-alt    { background-color: $blue-alt    !important; }
.bg-light-blue  { background-color: $light-blue  !important; }
.bg-cyan        { background-color: $cyan        !important; }
.bg-teal-alt    { background-color: $teal-alt    !important; }
.bg-green-alt   { background-color: $green-alt   !important; }
.bg-light-green { background-color: $light-green !important; }
.bg-lime-alt    { background-color: $lime-alt    !important; }
.bg-yellow-alt  { background-color: $yellow-alt  !important; }
.bg-amber       { background-color: $amber       !important; }
.bg-orange-alt  { background-color: $orange-alt  !important; }
.bg-deep-orange { background-color: $deep-orange !important; }
.bg-brown       { background-color: $brown       !important; }

.fw-100 { font-weight: 100 !important; }
.fw-200 { font-weight: 200 !important; }
.fw-300 { font-weight: 300 !important; }
.fw-400 { font-weight: 400 !important; }
.fw-500 { font-weight: 500 !important; }
.fw-600 { font-weight: 600 !important; }
.fw-700 { font-weight: 700 !important; }
.fw-800 { font-weight: 800 !important; }
.fw-900 { font-weight: 900 !important; }
.r-0 { right:  0; }
.t-0 { top:    0; }
.b-0 { bottom: 0; }
.l-0 { left:   0; }

.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-15 {
  width: 20%;
  float: left;
}
.col-xs-offset-18 { margin-left: 12.5%; }

@media (min-width: 768px) {
  .col-sm-15 {
    width: 20%;
    float: left;
  }
  .col-sm-offset-18 { margin-left: 12.5%; }
}
@media (min-width: 992px) {
  .col-md-15 {
    width: 20%;
    float: left;
  }
  .col-md-offset-18 { margin-left: 12.5%; }
}
@media (min-width: 1200px) {
  .col-lg-15 {
    width: 20%;
    float: left;
  }
  .col-lg-offset-18 { margin-left: 12.5%; }
}
@media (min-width: 1365px) {
  .col-xlg-2 {
    width: 16.66667%;
    float: left;
  }
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.lead-alt {
  font-weight: 400;
  font-family: $raleway;
  font-size: 1.25rem;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
}

.display-4-alt {
  font-size: 3rem;
  font-weight: 300;
}


.display-5 {
  font-size: 2.5rem;
  font-weight: 300;
}

@media screen and (max-width: $screen-tn-max) {
  .display-1 {
    font-size: 4.5rem;
  }

  .display-2 {
    font-size: 3.75rem;
  }

  .display-3 {
    font-size: 3rem;
  }

  .display-4 {
    font-size: 2.25rem;
  }

  .display-4-alt {
    font-size: 1.875rem;
  }

  .display-5 {
    font-size: 1.5rem;
  }
}

.average-heading {
  font-size: 2.5rem;
  font-weight: 400;
  font-family: $raleway;
}

.average-heading-alt {
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 400;
  font-family: $raleway;
}

.m-a-0 { margin: 0 !important; }
.m-t-0 { margin-top: 0 !important; }
.m-r-0 { margin-right: 0 !important; }
.m-b-0 { margin-bottom: 0 !important; }
.m-l-0 { margin-left: 0 !important; }

.m-x-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.m-y-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-a { margin: 1rem !important; }
.m-t { margin-top: 1rem !important; }
.m-r { margin-right: 1rem !important; }
.m-b { margin-bottom: 1rem !important; }
.m-l { margin-left: 1rem !important; }

.m-x {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.m-y {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.m-x-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.m-a-md { margin: 1.5rem !important; }
.m-t-md { margin-top: 1.5rem !important; }
.m-r-md { margin-right: 1.5rem !important; }
.m-b-md { margin-bottom: 1.5rem !important; }
.m-l-md { margin-left: 1.5rem !important; }

.m-x-md {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.m-y-md {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.m-a-lg { margin: 3rem !important; }
.m-t-lg { margin-top: 3rem !important; }
.m-r-lg { margin-right: 3rem !important; }
.m-b-lg { margin-bottom: 3rem !important; }
.m-l-lg { margin-left: 3rem !important; }

.m-x-lg {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.m-y-lg {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.p-x-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.p-y-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-a { padding: 1rem !important; }
.p-t { padding-top: 1rem !important; }
.p-r { padding-right: 1rem !important; }
.p-b { padding-bottom: 1rem !important; }
.p-l { padding-left: 1rem !important; }

.p-a-0 { padding: 0 !important; }
.p-t-0 { padding-top: 0 !important; }
.p-r-0 { padding-right: 0 !important; }
.p-b-0 { padding-bottom: 0 !important; }
.p-l-0 { padding-left: 0 !important; }

.p-x {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.p-y {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.p-a-md { padding: 1.5rem !important; }
.p-t-md { padding-top: 1.5rem !important; }
.p-r-md { padding-right: 1.5rem !important; }
.p-b-md { padding-bottom: 1.5rem !important; }
.p-l-md { padding-left: 1.5rem !important; }

.p-x-md {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.p-y-md {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.p-a-lg { padding: 3rem !important; }
.p-t-lg { padding-top: 3rem !important; }
.p-r-lg { padding-right: 3rem !important; }
.p-b-lg { padding-bottom: 3rem !important; }
.p-l-lg { padding-left: 3rem !important; }

.p-x-lg {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.p-y-lg {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pos-f-t {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.circle-block       { border-radius: 200px !important; }
.rounded-block      { border-radius: 3px !important; }
.square-block       { border-radius: 0 !important; }
.text-center-alt    { margin: 0 auto; }
.inline             { display: inline; }
.inline-block       { display: inline-block; }
.block              { display: block !important; }
.relative           { position: relative; }
.no-padding         { padding: 0; }
.position-fixed     { position: fixed; }
.overflow-hidden    { overflow: hidden !important; }
.font-lato          { font-family: $lato !important; }
.font-raleway       { font-family: $raleway !important; }
.font-roboto        { font-family: $roboto !important; }
.font-montserrat    { font-family: $montserrat !important; }
.slab               { font-family: $oswald !important; }
.color-555          { color: #555; }
.color-333          { color: #333 !important; }
.color-eee          { color: #eee !important; }
.color-red-custom   { color: $red !important; }
.no-border          { border: 0 !important; }

.vmiddle * { vertical-align: middle; }

.fs-table-vertical-middle {
  display: table;
  width: 100%;
  height: 100vh;
  > div {
    display: table-cell;
    vertical-align: middle;
  }
}

.default-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,.5);
}

.alt-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.5);
}

.bottom-gradient {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 25%,rgba(255,255,255,1) 100%);
}

.text-left   { text-align: left !important; }
.text-center { text-align: center !important; }
.text-right  { text-align: right !important; }
.text-capitalize-force { text-transform: capitalize !important; }

.kate-bg-primary    { background-color: $blue                 !important; }
.kate-bg-success    { background-color: $green                !important; }
.kate-bg-info       { background-color: $teal                 !important; }
.kate-bg-warning    { background-color: $orange               !important; }
.kate-bg-danger     { background-color: $red                  !important; }
.bg-555             { background-color: #555                  !important; }
.bg-solid-gray      { background-color: #f8f8f8               !important; }
.bg-half-black      { background-color: rgba(0,0,0,.5)        !important; }
.bg-half-black-hard { background-color: rgba(0,0,0,.75)       !important; }
.bg-half-white      { background-color: rgba(255,255,255,.5)  !important; }
.bg-half-white-hard { background-color: rgba(255,255,255,.75) !important; }

@mixin center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
  float: none;
}

@media screen and (max-width: $screen-tn-max) {
  .bg-half-black-hard {
    color: #eee !important;
  }
}

@media screen and (max-width: $screen-xs-max) {
  .col-xs-no-padding   { padding: 0; }
  .col-xs-center-block { @include center-block; }
  .col-xs-text-left    { text-align: left !important; }
  .col-xs-text-right   { text-align: right !important; }
  .col-xs-text-center  { text-align: center !important; }
  .xs-m-a-0 { margin: 0 !important; }
  .xs-m-t-0  { margin-top: 0 !important; }
  .xs-m-r-0  { margin-right: 0 !important; }
  .xs-m-b-0  { margin-bottom: 0 !important; }
  .xs-m-l-0  { margin-left: 0 !important; }
  .xs-m-b    { margin-bottom: 1rem !important; }
  .xs-m-b-md { margin-bottom: 1.5rem !important; }
}


@media screen and (min-width: $screen-sm) and (max-width: $screen-sm-max) {
  .col-sm-no-padding   { padding: 0; }
  .col-sm-center-block { @include center-block; }
  .col-sm-text-left    { text-align: left !important; }
  .col-sm-text-right   { text-align: right !important; }
  .col-sm-text-center  { text-align: center !important; }
  .sm-m-a-0 { margin: 0 !important; }
  .sm-m-t-0 { margin-top: 0 !important; }
  .sm-m-r-0 { margin-right: 0 !important; }
  .sm-m-b-0 { margin-bottom: 0 !important; }
  .sm-m-l-0 { margin-left: 0 !important; }
  .sm-m-b    { margin-bottom: 1rem !important; }
  .sm-m-b-md { margin-bottom: 1.5rem !important; }
}

@media screen and (min-width: $screen-md) and (max-width: $screen-md-max) {
  .col-md-no-padding   { padding: 0; }
  .col-md-center-block { @include center-block; }
  .col-md-text-left    { text-align: left !important; }
  .col-md-text-right   { text-align: right !important; }
  .col-md-text-center  { text-align: center !important; }
  .md-m-a-0 { margin: 0 !important; }
  .md-m-t-0 { margin-top: 0 !important; }
  .md-m-r-0 { margin-right: 0 !important; }
  .md-m-b-0 { margin-bottom: 0 !important; }
  .md-m-l-0 { margin-left: 0 !important; }
}

@media screen and (min-width: $screen-lg) {
  .col-lg-no-padding   { padding: 0; }
  .col-lg-center-block { @include center-block; }
  .col-lg-text-left    { text-align: left !important; }
  .col-lg-text-right   { text-align: right !important; }
  .col-lg-text-center  { text-align: center !important; }
  .lg-m-a-0 { margin: 0 !important; }
  .lg-m-t-0 { margin-top: 0 !important; }
  .lg-m-r-0 { margin-right: 0 !important; }
  .lg-m-b-0 { margin-bottom: 0 !important; }
  .lg-m-l-0 { margin-left: 0 !important; }
}

// #####################
// ### block margins ###
// #####################

.margin-tiny            { margin:         $offset-tiny auto !important; }
.margin-top-tiny        { margin-top:     $offset-tiny !important; }
.margin-bottom-tiny     { margin-bottom:  $offset-tiny !important; }
.margin-small           { margin:         $offset-small auto !important; }
.margin-top-small       { margin-top:     $offset-small !important; }
.margin-bottom-small    { margin-bottom:  $offset-small !important; }
.margin-smedium         { margin:         $offset-smedium auto !important; }
.margin-top-smedium     { margin-top:     $offset-smedium !important; }
.margin-bottom-smedium  { margin-bottom:  $offset-smedium !important; }
.margin-medium          { margin:         $offset-medium auto !important; }
.margin-top-medium      { margin-top:     $offset-medium !important; }
.margin-bottom-medium   { margin-bottom:  $offset-medium !important; }
.margin-lmedium         { margin:         $offset-lmedium auto !important; }
.margin-top-lmedium     { margin-top:     $offset-lmedium !important; }
.margin-bottom-lmedium  { margin-bottom:  $offset-lmedium !important; }
.margin-large           { margin:         $offset-large auto !important; }
.margin-top-large       { margin-top:     $offset-large !important; }
.margin-bottom-large    { margin-bottom:  $offset-large !important; }
.margin-huge            { margin:         $offset-huge auto !important; }
.margin-top-huge        { margin-top:     $offset-huge !important; }
.margin-bottom-huge     { margin-bottom:  $offset-huge !important; }
.padding-tiny           { padding-top:    $offset-tiny !important; padding-bottom: $offset-tiny !important; }
.padding-top-tiny       { padding-top:    $offset-tiny !important; }
.padding-bottom-tiny    { padding-bottom: $offset-tiny !important; }
.padding-small          { padding-top:    $offset-small !important; padding-bottom: $offset-small !important; }
.padding-top-small      { padding-top:    $offset-small !important; }
.padding-bottom-small   { padding-bottom: $offset-small !important; }
.padding-smedium        { padding-top:    $offset-smedium !important; padding-bottom: $offset-smedium !important; }
.padding-top-smedium    { padding-top:    $offset-smedium !important; }
.padding-bottom-smedium { padding-bottom: $offset-smedium !important; }
.padding-medium         { padding-top:    $offset-medium !important; padding-bottom: $offset-medium !important; }
.padding-top-medium     { padding-top:    $offset-medium !important; }
.padding-bottom-medium  { padding-bottom: $offset-medium !important; }
.padding-lmedium        { padding-top:    $offset-lmedium !important; padding-bottom: $offset-lmedium !important; }
.padding-top-lmedium    { padding-top:    $offset-lmedium !important; }
.padding-bottom-lmedium { padding-bottom: $offset-lmedium !important; }
.padding-large          { padding-top:    $offset-large !important; padding-bottom: $offset-large !important; }
.padding-top-large      { padding-top:    $offset-large !important; }
.padding-bottom-large   { padding-bottom: $offset-large !important; }
.padding-huge           { padding-top:    $offset-huge !important; padding-bottom: $offset-huge !important; }
.padding-top-huge       { padding-top:    $offset-huge !important; }
.padding-bottom-huge    { padding-bottom: $offset-huge !important; }

// #####################
// ### blocks height ###
// #####################

.block-tiny            { min-height: $block-tiny-height;     // 250
  .row:only-child      { min-height: $block-tiny-height; }}
.block-tiny-even       { height: $block-tiny-height;         // 250
  .row:only-child      { height: $block-tiny-height; }}
.block-tiny-alt        { min-height: $block-tiny-alt-height;   // 200
  .row:only-child      { min-height: $block-tiny-alt-height; }}
.block-tiny-alt-even   { height: $block-tiny-alt-height;     // 200
  .row:only-child      { height: $block-tiny-alt-height; }}
.block-small           { min-height: $block-small-height;    // 350
  .row:only-child      { min-height: $block-small-height; }}
.block-small-even      { height: $block-small-height;      // 350
  .row:only-child      { height: $block-small-height; }}
.block-small-alt       { min-height: $block-small-alt-height;  // 300
  .row:only-child      { min-height: $block-small-alt-height; }}
.block-small-alt-even  { height: $block-small-alt-height;    // 300
  .row:only-child      { height: $block-small-alt-height; }}
.block-medium          { min-height: $block-medium-height;   // 450
  .row:only-child      { min-height: $block-medium-height; }}
.block-medium-even     { height: $block-medium-height;     // 450
  .row:only-child      { height: $block-medium-height; }}
.block-medium-alt      { min-height: $block-medium-alt-height; // 400
  .row:only-child      { min-height: $block-medium-alt-height; }}
.block-medium-alt-even { height: $block-medium-alt-height;   // 400
  .row:only-child      { height: $block-medium-alt-height; }}
.block-tall            { min-height: $block-tall-height;     // 550
  .row:only-child      { min-height: $block-tall-height; }}
.block-tall-even       { height: $block-tall-height;       // 550
  .row:only-child      { height: $block-tall-height; }}
.block-tall-alt        { min-height: $block-tall-alt-height;   // 500
  .row:only-child      { min-height: $block-tall-alt-height; }}
.block-tall-alt-even   { height: $block-tall-alt-height;     // 500
  .row:only-child      { height: $block-tall-alt-height; }}


// ##########################
// ### spacers & dividers ###
// ##########################

.spacer-tiny   { height: $spacer-tiny-height; }
.spacer-small  { height: $spacer-small-height; }
.spacer-medium { height: $spacer-medium-height; }
.spacer-tall   { height: $spacer-tall-height; }


// #############
// ### other ###
// #############

input.input-fullwidth,
textarea.textarea-fullwidth,
select.select-fullwidth {
  width: 100% !important;
}
