/* menu general styles */
.menu {
  font-family: $roboto;
  font-size: 14px;
  .navbar-toggle {
    margin: 0 15px;
    padding-top: 0;
    padding-bottom: 0;
    transition: color .25s;
    position: absolute;
  }

  li { list-style-type: none; }
  hr {
    border-top: 1px solid #f1f1f1;
    margin-left: 5px;
    margin-right: 5px;
  }
  .menu-spacer { height: 5px; }


  .dropdown-menu-left { left: 0 !important; }
  .dropdown-menu-custom-width-1 { min-width: 300px; }
  .dropdown-menu-custom-width-2 { min-width: 350px; }
  .dropdown-menu-custom-width-3 { min-width: 450px; }
  .dropdown-menu-custom-width-4 { min-width: 475px; }

  .multi-column {
    padding: 0 !important;
    .row > div {
      border-right: 1px solid #f1f1f1;
      &:last-child { border-right: 0; }
    }
  }

  .dropdown-menu {
    /* override bootstrap styles */
    padding-top: 15px;
    padding-bottom: 15px;
    border: 0;
    box-shadow: 1px 0   0         #ddd,
               -1px 0   0         #ddd,
                0  -1px 0         #ddd,
                0  15px 40px 10px rgba(0, 0, 0, 0.1);
    .row {
      margin-left: 0;
      margin-right: 0;
      > div { padding: 15px 0; }
    }
    li {
      &:not(.row):hover { background-color: rgba(0, 0, 0, .025); }
      > a {
        position: relative;
        left: 0;
      }
    }
  }

  .dropdown-menu > .disabled > a,
  .dropdown-menu > .disabled > a:hover,
  .dropdown-menu > .disabled > a:focus {
    color: #AAA;
    padding: 3px 20px;
  }

  .dropup .dropdown-menu {
    margin-bottom: 0;
    padding: 0;
  }

  .dropdown-menu li a:focus {
    outline: none;
  }

  .dropdown-menu > li > a:hover,
  .dropdown-menu > li > a:focus {
    background-color: transparent;
  }

  .multi-column-dropdown {
    padding: 0;
    > li > a { text-decoration: none; }
    > .dropdown-submenu:after { right: 20px; }
  }

  .navbar .navbar-nav > .open {
    > a,
    > a:focus,
    > a:hover {
      background: rgba(0, 0, 0, 0);
    }
  }

  .candy-item {
    border-top: 5px solid white;
    a {
      pointer-events: none;
      cursor: default;
    }
    &:hover { background-color: transparent !important; }
  }

  .candy-item-fw { border-bottom: 5px solid white; }

  .row-grid-12 {
    .multi-column-dropdown,
    .dropdown-menu {
      li {
        padding: 0;
      }
    }
  }

  .rg-12-title {
      position: relative;
      text-align: center;
      display: table;
      line-height: 20px;
      letter-spacing: .5px;
      height: 78px;
      padding: 0 !important;
      span {
        vertical-align: middle;
        display: table-cell;
        padding: 5px;
        font-family: $oswald;
        font-size: 16px;
        font-weight: 500;
      }
  }
  .rg-12-title-solo {
    height: 39px;
    letter-spacing: 0;
  }
  .rg-12-empty { position: absolute; }
  .rg-12-cell {
    padding: 0 !important;
    border-right: 1px solid #f5f5f5 !important;
    li > a {
      line-height: 19px !important;
    }
    &:last-child { border-right: 0 !important; }
  }

  .candy-item-solo { margin-left: 5px; margin-right: 5px; }
  .candy-item-1 { margin-left: 5px; }
  .candy-item-2,
  .candy-item-3,
  .candy-item-4 {
    position: relative;
  }

  .navbar .dropdown-menu > li > a:hover,
  .navbar .dropdown-menu > li > a:focus,
  .multi-column-dropdown > li > a:hover,
  .multi-column-dropdown > li > a:focus {
    background: rgba(0, 0, 0, 0);
    left: 3px;
  }

  .dropdown-menu > .dropdown-submenu:after { right: 20px; }

  .navbar {
    border: 0;
    z-index: 20 !important;
    padding: 20px 0;
    transition: background 0.5s ease-in-out, padding-top 0.5s ease-in-out, padding-bottom 0.5s ease-in-out, box-shadow .5s;
    backface-visibility: hidden;
    background: rgba(255, 255, 255, 1);
    text-transform: uppercase;
    border-radius: 0;
    .nav {
      float: right;
      position: static;
      > li > a {
        letter-spacing: .5px;
        font-weight: 500;
        line-height: 61px; // inherit + 1px
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    .nav > li > a:hover,
    .nav > li > a:focus { background: none !important; }
    .container { position: relative; }
    .dropdown-menu {
      left: auto;
      li > a {
        text-transform: uppercase;
        letter-spacing: .5px;
        padding: 12px 35px;
        color: #444;
        display: block;
        clear: both;
        font-size: 13px;
        font-weight: 500;
        white-space: nowrap;
        line-height: 15px !important;
        overflow: hidden;
      }
    }
    .dropdown-content { padding: 20px 25px; }
    .dropdown.dropdown-fw > .dropdown-menu {
      left: 0;
      right: 0;
    }
    .navbar-nav > .open > a { transition: all 0.25s; }
    .collapse,
    .dropup,
    .dropdown {
      position: static;
    }
    .button-navbar {
      margin: 10px auto;
      font-family: $roboto;
      font-weight: 500;
      text-shadow: none;
      padding-left: 2rem;
      padding-right: 2rem;
    }
    &.navbar-border {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    &.navbar-static {
      margin-bottom: 0;
    }
  }

  .navbar-radio {
    padding: 0 35px;
    font-family: $oswald;
    font-size: 16px;
    font-weight: 500;
    form div:last-child {
      margin-bottom: 0;
    }
  }

  .navbar-b-p-0 { padding: 20px 0 0 0; }

  .navbar nav ul.nav .navbar-user-icon {
    margin-left: 0;
    box-shadow: 0 0 0 1px #ddd;
    height: 33px;
    top: 13px;
    position: relative !important;
    border-radius: 1px;
    a.dropdown-toggle {
      line-height: 33px !important;
      *:last-child {
        position: relative;
        left: 2px;
      }
      &:hover,
      &:focus {
        color: #444 !important;
        background: #eee !important;
      }
    }
    .user-icon-messages .label {
      border-radius: 200px;
      width: 17px;
      display: inline-block;
      height: 17px;
      line-height: 17px;
      padding: 0;
    }

    ul.dropdown-menu hr {
      margin-bottom: 15px;
      margin-top: 15px;
    }
  }

  .navbar nav ul.nav li.navbar-user-icon.open a.dropdown-toggle {
    color: #444 !important;
    background: #eee !important;
  }

  /* navbar transparent */

  .navbar-transparent,
  .navbar-always-transparent {
    .nav > li {
      &.open > a { color: #111 !important; }
      > a,
      > a:hover,
      > a:focus {
        color: #111 !important;
      }
    }
    .logo-wrapper,
    .navbar-header-buttons > li > a,
    .navbar-header-buttons .navbar-single-icon a,
    .navbar-toggle { color: #111 !important; }
    .icon-bar { background: #111; }

    // used for demo purposes
    &.solid-color.navbar {
      nav ul.nav > li {
        &.open > a { color: #111 !important; }
        > a,
        > a:hover,
        > a:focus {
          color: #111 !important;
        }
      }
      div.navbar-header {
        .logo-wrapper,
        .navbar-header-buttons > li > a,
        .navbar-header-buttons .navbar-single-icon a,
        .navbar-toggle { color: #111 !important; }
        .icon-bar { background: #111; }
      }
    }
    // ---
  }

  .navbar-transparent.navbar-solid {
    .nav > li {
      &.open > a { color: #444 !important; }
      > a,
      > a:hover,
      > a:focus {
        color: #444 !important;
      }
    }
    .logo-wrapper,
    .navbar-header-buttons > li > a,
    .navbar-header-buttons .navbar-single-icon a,
    .navbar-toggle { color: #444 !important; }
    .icon-bar { background: #444; }
  }

  .navbar-transparent-inverse {
    .nav > li {
      &.open > a { color: #eee !important; }
      > a,
      > a:hover,
      > a:focus {
        color: #eee !important;
      }
    }

    .logo-wrapper,
    .navbar-header-buttons > li > a,
    .navbar-header-buttons .navbar-single-icon a,
    .navbar-toggle { color: #eee !important; }
    .icon-bar { background: #eee; }


    // used for demo purposes
    &.solid-color.navbar {
      nav ul.nav > li {
        &.open > a { color: #eee !important; }
        > a,
        > a:hover,
        > a:focus {
          color: #eee !important;
        }
      }
      div.navbar-header {
        .logo-wrapper,
        .navbar-header-buttons > li > a,
        .navbar-header-buttons .navbar-single-icon a,
        .navbar-toggle { color: #eee !important; }
        .icon-bar { background: #eee; }
      }
    }
    // ---

    &.navbar-solid {
      .nav > li {
        &.open > a { color: #444 !important; }
        > a,
        > a:hover,
        > a:focus {
          color: #444 !important;
        }
      }
      .logo-wrapper,
      .navbar-header-buttons > li > a,
      .navbar-header-buttons .navbar-single-icon a,
      .navbar-toggle { color: #444 !important; }
      .icon-bar { background: #444; }

      // used for demo purposes
      &.solid-color.navbar {
        nav ul.nav > li {
          &.open > a { color: #444 !important; }
          > a,
          > a:hover,
          > a:focus {
            color: #444 !important;
          }
        }
        div.navbar-header {
          .logo-wrapper,
          .navbar-header-buttons > li > a,
          .navbar-header-buttons .navbar-single-icon a,
          .navbar-toggle { color: #444 !important; }
          .icon-bar { background: #444; }
        }
      }
      // ---
    }
    &.navbar-border {
      border-bottom: 1px solid rgba(255,255,255,0.2);
    }
  }

  .navbar-always-transparent {
    background: transparent !important;
    box-shadow: none !important;
  }

  .navbar-border.navbar-solid {
    border-bottom: 0 !important;
  }



  .navbar-no-collapse,
  .menu-navbar-collapse {
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
  }
  .menu-navbar-collapse { padding: 0; }
  .navbar-solid {
    background: rgba(255, 255, 255, 1) !important;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
  }

  /* end of navbar transparent */


  /* collapse menu on scrolling */
  .nav { box-sizing: border-box; } // move



  .affix-top {
    position: absolute;
    width: 100%;
  }

  .navbar-affix {
    z-index: 10;
    &.affix {
      position: fixed;
      top: 0;
      width: 100%;
    }
  }

  /* items on left */
  .navbar-items-left .nav {
    float: left !important;
  }

  /* items in the center */
  .navbar-items-center {
    .nav {
      float: none !important;
      display: inline-block !important;
    }
    nav { text-align: center !important; }
  }


  /* | multilevel
     V */
  .dropdown-submenu {
    position: relative;
    padding-right: 20px;
    > .dropdown-menu {
      top: 0;
      left: calc(100% + 1px);
      margin-top: -15px;
    }
    &.pull-left {
      float: none;
      > .dropdown-menu {
        left: -100%;
        margin-left: 10px;
        border-radius: 6px 0 6px 6px;
      }
    }
    &:after {
      position: absolute;
      content: "\f105";
      font: normal normal normal 14px/1 FontAwesome;
      top: 12.5px;
      transition: all 0.25s;
    }
    &:hover > a:after {
      transition: all 0.25s;
    }
  }

  .bg-navbar-search {
    background: white !important;
    .nav .navbar-search-button a#search-button-link {
      color: rgba(0,0,0,.5) !important;
      &:hover,
      &:focus {
        color: black !important;
      }
    }
  }



  .dropdown-menu,
  .multi-column-dropdown {
    border-radius: 0;
  }

  #navbar-search-input {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    input {
      width: 100%;
      height: 100%;
      border: 0;
      box-shadow: none !important;

      // gcs override
      background: 0 0 !important;
      text-indent: 0 !important;
      font-size: 25px !important;
      font-weight: 300 !important;
      font-family: Roboto, sans-serif;
    }
  }



  .navbar-collapse {
    /* bootstrap style for affix menu */
    max-height: 340px;
    margin-left: -15px !important;
    margin-right: -15px !important;
    padding-right: 0;
    padding-left: 0;
  }

  .button-word { margin-left: 5px; font-size: 16px; }
  .navbar-search-button { z-index: 200; }
  .menu-unwrapped .navbar-collapse { max-height: none; }
  .navbar-fixed-bottom .dropdown-submenu .dropdown-menu { bottom: 0; top: auto; }
  .navbar-fixed-top { border-width: 1px; }
  .dropdown .caret { transition: transform 0.25s; }
  .dropdown.open a .caret { transform: rotate(180deg); }
  .navbar-single-button-left button { left: 0; }
  .media-object { max-width: 300px; }
  .caption, .media { text-transform: none; }
  .logo-wrapper { font-weight: 300; }
  .fullwidth-bar {
    line-height: initial;
    display: inline-block;
    vertical-align: middle;
    .icon-bar { width: 100%; }
    span:first-child { margin-top: 5px; }
    span:last-child {
      margin-top: 3px;
      display: inline-block;
      letter-spacing: -1px;
    }
  }
  .button-icon-word > span {
    display: inline-block;
    vertical-align: middle;
  }

  .button-icon-default {
    font-size: 20px;
    margin: auto 10px;
  }

  .no-hover:hover { background-color: transparent !important; }

  .dropdown-content .thumbnail {
    border: 0;
    margin-bottom: 0;
  }

  .dropdown-menu-title {
    letter-spacing: 1px;
    display: block;
    position: relative;
    padding: 1px 15px 15px 15px;
    margin: 0 5px 0 5px;
    font-family: $raleway;
    font-size: 15px;
    font-weight: 800;
    text-align: center;
    border-bottom: 1px dashed #f1f1f1;
    color: #555;
  }

  .dropdown-menu-title-double {
    line-height: 20px;
    font-size: 17px;
    padding: 0 15px 32px 15px;
    margin: 7px 5px 0 5px;
  }

  .menu-item-empty { height: 34px; }

  .navbar-language-icon {
    height: 13px;
    vertical-align: top;
  }

  .dropdown-grid {
    width: 175px;
    padding: 5px;
    li {
      width: 55px;
      float: left;
      a {
        padding: 10px !important;
        text-align: center;
        margin: 0 !important;
        &:hover {
          left: 0 !important;
        }
      }
    }
  }

  .dropdown-img .row {
    background: url("/img/shop/sale-girl.png") right top no-repeat;
    background-size: contain;
  }

  .navbar .label {
    padding: 3px;
    border-radius: 3px;
    font-weight: 500;
    font-size: 75%;
    line-height: 0;
    vertical-align: middle;
    position: relative;
    bottom: 1px;
    &.label-cart {
      border-radius: 200px;
      text-align: center;
      width: 18px;
      height: 18px;
      display: inline-block;
      font-weight: bold;
      font-size: 8px;
      padding-top: 9px;
      bottom: 10px;
      right: 5px;
      font-family: $lato;
    }
  }

  .icon-bar { background: #444; transition: background .25s; }

  .navbar-header-buttons {
    display: inline-block;
    margin-bottom: 0;
    position: absolute;
    right: 15px;
    font-size: 20px;
    li {
      display: inline-block;
      margin: 0 20px;
    }
  }

  .navbar-single-button {
    padding: 0 !important;
    button {
      top: 0;
      right: 0;
      border: 0;
      line-height: 60px;
      height: 60px;
    }
    .logo-wrapper {
      text-align: center;
      .text-logo {
        position: static;
      }
      a {
        transform-origin: center;
        transform: scale(0.75);
      }
    }

  }

  .menu-carousel {
    padding-top: 12px;
    height: 78px;
    .slick-track,
    .slick-list,
    .slick-slide img {
      height: 100%;
    }
    .slick-track {
      width: 100%;
    }
  }

  .menu-button { padding-left: 30px; }
}

input.gsc-input {
  background: none !important;
  text-indent: 0 !important;
  font-size: 25px !important;
  font-weight: 300 !important;
  font-family: $roboto;
  &:focus { @include placeholder { color: #777 !important; } }
  @include placeholder { font-size: 25px !important; }
}

.gsib_a { padding: 0 !important; }
.gsib_b, .gsc-search-button { display: none; }

.gsc-control-cse {
  padding: 8px 0 !important;
}

.gsc-results-wrapper-nooverlay.gsc-results-wrapper-visible {
  box-shadow: 0 8px 8px rgba(0,0,0,.20);
  margin-top: 8px;
  padding: 0 20px 10px;
  background: #fff;
}

.gsc-input-box {
  height: initial !important;
  border: 0 !important;
  background: transparent !important;
}

.gsc-wrapper {
  height: calc(100vh - 150px);
  overflow-y: scroll;
}

#___gcse_0, .gsc-control-cse, .gsc-control-wrapper-cse, .gsc-search-box {
  height: 100%;
}

.gsc-search-box .gsc-input>input:hover, .gsc-input-box-hover,
.gsc-search-box .gsc-input>input:focus, .gsc-input-box-focus {
  box-shadow: none !important;
}

table.gsc-search-box, form.gsc-search-box { margin-bottom: 0 !important; }
//.cse .gsc-control-cse,
.gsc-control-cse {
  background: transparent !important;
  border: 0 !important;
}

.gsc-control-wrapper-cse {
  background: #fff;
}

.navbar .navbar-form {
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 0;
  margin-bottom: 0;
}

.navbar-inverse .navbar-form input[type="text"] {
  color: #ccc;
}

.overlay-search {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.65);
  z-index: 10000;
  display: none;
  .button-group {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    font-size: 0;
  }
  input {
    height: 60px;
    border: 2px;
    padding: 15px !important;
    line-height: 60px;
    font-size: 24px;
    width: 74%;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    background-color: white !important;
    box-shadow: none !important;
    color: #333 !important;
  }
  button {
    float: none !important;
    background-color: #111;
    padding: 0 25px;
    font-size: 28px;
    color: #bbb !important;
    vertical-align: bottom;
    &:hover {
      background: white;
      color: #111 !important;
    }
  }
  .overlay-search-close {
    width: 80px;
    height: 80px;
    position: absolute;
    right: 20px;
    top: 20px;
    background: url("/img/app/cross.png") no-repeat center center;
    z-index: 100;
    border: 2px solid transparent;
    transition: all 0.25s;
    background-size: 60%;
    cursor: pointer;
    &:hover {
      border: 2px solid white;
      opacity: 1;
    }
    &:focus { outline: none !important; }
  }
}

.dropdown-content-table {
  font-family: $lato;
  text-transform: none;
  tr th {
    white-space: nowrap;
  }
}

@media screen and (max-width: $screen-sm-max) {
  .menu {
    .navbar .dropdown-menu > li > a:hover,
    .navbar .dropdown-menu > li > a:focus,
    .multi-column-dropdown > li > a:hover,
    .multi-column-dropdown > li > a:focus {
      left: 0;
    }

    .navbar-header {
      height: 75px;
      line-height: 75px;
    }

    .navbar {
      .nav > li > a {
        margin-left: 25px;
        margin-right: 25px;
        line-height: 43px;
        border-bottom: 1px solid #eee;
        &:after {
          position: absolute;
          content: "\f105";
          font: normal normal normal 14px/1 FontAwesome;
          transition: all 0.25s;
          right: 20px;
          top: 14px;
        }
        &:hover {
          &:after {
            transform: rotate(90deg);
          }
        }
      }
      .dropdown-menu li > a {
        margin: auto 0 auto 25px;
        border-bottom: 1px solid #eee;
        padding: 15px 0;
      }
    }

    .navbar .dropdown-submenu > .dropdown-menu {
      margin-left: 25px;
      margin-top: 0;
      border-bottom: 0;
    }
    .dropdown-menu li:hover {
      background-color: transparent;
    }
    .dropdown-submenu {
      padding-right: 0 !important;
      &:after { top: 14px; }
      &:hover {
        &:after {
          transform: rotate(90deg);
        }
      }
    }
    ul.nav > .dropdown > .dropdown-menu {
      margin-left: 25px;
      margin-right: 25px;
      border-bottom: 0;
    }

    /* disabling this causes menu uncollapse on click when user is mobile */
    .navbar, .dropdown-menu { padding: 0; }
    nav {
      margin: 0 !important;
      padding: 0;
    }

    .nav {
      width: 100%;
      li { float: none; }
    }
    .nav li { overflow-x: hidden; }
    ul { padding: 0; }
    .navbar-nav { margin: 0 0 35px 0; }
    .divider { width: 100%; }
    .navbar { overflow: hidden; }

    .media {
      a {
        padding: 0;
        margin-bottom: 10px;
        display: inline-block;
        float: none !important;
        img { display: inline-block; }
      }
      .media-body-content { text-align: left; }
    }
    .dropdown .table {
      position: relative;
      left: -30px;
      margin-bottom: 0;
    }
    .dropdown-content-table { overflow: scroll; }
    .multi-column .row > div { border-right: 0 !important; padding: 0; }

    li.media > a { padding: 0 !important; }
    .dropdown-content .caption .button:first-child { margin-bottom: 5px; }

    .candy-item { display: none; }
    .col-sm-12 .candy-item-solo { display: block; }

    .dropdown-menu-title {
      margin-left: 25px;
      border-bottom: 0 !important;
      text-transform: uppercase;
      padding-top: 30px;
    }

    .menu-carousel {
      height: 150px;
      padding-left: 25px;
      .slick-slide img {
        width: 100%;
        height: auto !important;
      }
    }

    .multi-column-dropdown .menu-carousel {
      height: auto;
    }
    .logo-wrapper a {
      transform: scale(0.75);
      transform-origin: center 100%;
    }
  }
  .menu .logo-wrapper {
    text-align: center;
  }
  .dropdown-img .row {
    background: none !important;
  }
}


@media screen and (min-width: 500px) and (max-width: $screen-sm-max) {
  .menu-carousel {
    height: 100px;
  }
}

@media screen and (max-width: 500px) {
  .menu-carousel {
    height: 50px;
  }
  .navbar-header-buttons > li:last-child {
    margin: 0;
  }
}