.button-fill {
  z-index: 1;
  &:hover {
    color: whitesmoke;
  }
  &:before {
    content: "";
    position: absolute;
    background: #333;
    bottom: 0;
    left: 0;
    right: 100%;
    top: 0;
    z-index: -1;
    transition: right 0.2s;
  }
  &:hover:before {
    right: 0;
  }
}

.button-fill-left {
  &:before {
    left: 100%;
    right: 0;
    transition: left 0.2s;
  }
  &:hover:before {
    left: 0;
  }
}

.button-fill-bottom {
  &:before {
    bottom: 100%;
    right: 0;
    transition: bottom 0.2s;
  }
  &:hover:before {
    bottom: 0;
  }
}

.button-fill-top {
  &:before {
    top: 100%;
    right: 0;
    transition: top 0.2s;
  }
  &:hover:before {
    top: 0;
  }
}


.button-arrow {
  &:before {
    content: "→";
    position: relative;
    opacity: 0;
  }
  &:after {
    content: "→";
    position: relative;
    color: #fff;
    opacity: 0;
    transition: all .25s;
    left: 10px;
  }
  &:hover:after{
    opacity: 1;
  }
}

.button-slide, .button-slide-alt {
  overflow: hidden;
  &:after {
    content: "Kate Template";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    -webkit-transition: all 400ms cubic-bezier(0.680, -0.550, 0.265, 1.550);
  }
  &:before {
    content:"Nice & Neat →";
    height: 100%;
    width: 100%;
    position: absolute;
    left: -100%;
    opacity: 0;
    -webkit-transition: all 500ms cubic-bezier(0.680, -0.550, 0.265, 1.550);
  }
  &:hover:before {
    left: 0;
    opacity: 1;
    color: #fff;
  }
  &:hover:after {
    left: 100%;
    opacity: 0;
  }
}

.button-slide-alt {
  &:hover {
    background:none;
  }
  &:before {
    left:100%;
    color:#383736;
    -webkit-transition: all 425ms cubic-bezier(0.680, -0.550, 0.265, 1.550);
  }
  &:hover:before {
    left:0;
  }
}