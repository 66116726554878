.jumbotron {
  padding-left: 7rem;
  padding-right: 7rem;
  border: 0;
  a.button {
    font-size: 15px;
    span {
      position: relative;
      transition: 0.25s right;
      right: 0;
    }
    &:before {
      color: transparent;
      content: "\f0ed";
      font-weight: 400;
      text-shadow: none;
      transition: .25s padding, .25s color;
      padding-right: 3px;
    }
    &:hover {
      span { right: 10px; }
      &:before {
        color: white !important;
        padding-right: 13px;
      }
    }
  }
}

.jumbotron-no-anim a.button {
  &:before { content: ''; }
  &:hover span { right: 0; }
}

.jumbotron-bg-theme {
  color: #252525;
  .jumbotron-title a {
    color: #111 !important;
  }
}

.jumbotron-bg-black {
  background: #222;
  color: #ddd;
  a.button-border-white:hover:before {
    color: black !important;
  }
}

.jumbotron-no-border {
  border: 0 !important;
}

.jumbotron-border {
  background: transparent !important;
  border: 1px solid #ccc;
  border-radius: 2px;
}

// #####################
// ### attention box ###
// #####################

.attention-box {
  position: relative;
  padding: 35px 50px;
  border: 1px solid #ccc;
  border-radius: 2px;
  > .container {
    position: relative;
  }
  a.button {
    position: absolute;
    right: 50px;
    top: 0;
    bottom: 0;
    margin: auto;
    span {
      position: relative;
      transition: 0.25s right;
      right: 0;
      margin: auto 7px;
    }
    &:before {
      color: transparent;
      font-weight: 400;
      text-shadow: none;
      transition: 0.25s all;
    }
    &:hover {
      span { right: 12px; }
      &:before {
        color: white !important;
        padding-right: 12px;
      }
    }
  }
  .subtitle {
    font-size: 16px;
    margin-bottom: 0;
  }
  .title {
    font-family: $lato;
    a {
      transition: 0.25s color;
      display: inline-block;
    }
  }
}

.attention-box-primary .title a {
  color: #1B9AF7 !important;
  &:hover { color: #4CB0F9 !important; }
}

.attention-box-action .title a {
  color: #A5DE37 !important;
  &:hover { color: #B9E563 !important; }
}

.attention-box-highlight .title a {
  color: #FEAE1B !important;
  &:hover { color: #FEC04E !important; }
}

.attention-box-caution .title a {
  color: #FF4351 !important;
  &:hover { color: #FF7680 !important; }
}

.attention-box-royal .title a {
  color: #7B72E9 !important;
  &:hover { color: #a49ef0 !important; }
}

.attention-box-no-border {
  border: 0 !important;
}

.attention-box-fw {
  border-radius: 0;
  border-right: 0 !important;
  border-left: 0 !important;
}

.attention-box-left {
  .title, .subtitle { text-align: right; }
  a.button { left: 50px; right: auto; }
}

.attention-box-bottom {
  text-align: center;
  a.button { position: relative; right: auto; }
  .subtitle { margin-bottom: 1rem; }
}

.attention-box-bg-gray {
  background: #eee;
}

.attention-box-bg-parallax,
.attention-box-bg-image {
  padding: 50px;
  background-size: cover;
}

.attention-box-button-big {
  .text-section {
    width: 50%;
    padding: 0 10px;
  }
  a.button {
    width: 50%;
    right: 0 !important;
    height: 100%;
    font-size: 30px;
    &:before {
      content: '\200B';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }
    &:hover:before {
      padding-right: 0;
    }
  }
}

.attention-box-button-big-left {
  .text-section {
    margin-left: 52%;
  }
  a.button {
    right: auto !important;
    left: 0;
  }
}

.attention-box-button-big-third {
  .text-section {
    width: 65%;
  }
  a.button {
    width: 35%;
  }
  &.attention-box-button-big-left {
    .text-section {
      margin-left: 35%;
    }
  }
}

@media screen and (max-width: $screen-sm-max) {
  .attention-box-button-big {
    .text-section {
      width: initial;
      margin-left: auto !important;
    }
    a.button {
      width: 100%;
    }
  }
}



.attention-box-bg-dark {
  background: #bbb;
}

.attention-box-bg-black {
  background: #222;
  color: #ddd;
}

.attention-box-bg-theme,
.attention-box-bg-alt-theme {
  color: #111;
  .title a {
    color: #111 !important;
  }
}

.attention-box-half,
.attention-box-third {
  .subtitle {
    margin-bottom: 1rem;
  }
  a.button {
    position: relative;
    left: 0;
  }
}

.attention-box-third {
  .title a { margin-top: .5rem; }
  a.button {
    padding-left: 45px;
    padding-right: 45px;
  }
}

.attention-box-button-transparent a.button {
  span { transition: 0.25s color; }
  &:hover span {
    color: #111;
  }
}

.attention-box-button-transparent-dark a.button {
  span { transition: 0.25s color; color: #bbb; }
  &:hover span {
    color: #eee;
  }
}

.attention-box-no-anim a.button {
  &:before { content: ''; }
  &:hover span { right: 0; }
}

@media screen and (max-width: $screen-md-max) {
  .attention-box {
    padding: 35px;
    a.button {
      right: 35px;
    }
  }
  .attention-box-left a.button {
    left: 35px;
    right: auto
  }
  .attention-box-half .title a {
    margin-top: .5rem;
  }
}

@media screen and (max-width: $screen-sm-max) {
  .attention-box {
    .subtitle {
      margin-bottom: 1rem;
    }
    a.button {
      right: 0;
      position: relative;
      font-size: 19px;
    }
  }
  .attention-box-left {
    a.button {
      left: 0;
      font-size: 19px;
    }
    .title,
    .subtitle {
      text-align: left;
    }
  }
}

@media screen and (max-width: $screen-xs-max) {
  .attention-box {
    padding: 35px 15px;
    .title {
      a {
        margin-top: .5rem;
      }
    }
  }
}
