*, *:before, *:after { box-sizing: inherit; }

html {
  width: 100%;
  font-size: 15px;
  box-sizing: border-box;
  &.container { padding: 0; }
}

body {
  max-width: 100%;
  margin: 0 auto !important;
  font-size: 15px;
  font-family: $lato;
  color: #444;
  line-height: 1.5;
  overflow-x: hidden;
  &.container {
    .animsition         { width: inherit; }
    .navbar-affix.affix { width: inherit; }
    .parallax-mirror    { width: inherit !important; left: inherit !important; }
  }
}

.animsition-loading       { max-width: 100%;
  &:after                 { max-width: 100%; }}
.animsition-overlay-slide { max-width: 100%; }


a { color: #333; transition: all .25s; }
a:hover, a:focus { color: #000; text-decoration: none; }
a:focus { outline: none; }
a.link {
  border-bottom: 1px solid #ccc;
  &:hover { border-color: #676767; }
}

.button {
  transition: 0.25s all;
  font-family: $montserrat;
  font-size: 14px;
  text-shadow: 1px 1px 1px rgba(0,0,0,.1);
  letter-spacing: .5px;
  padding: 0 30px;
  position: relative;
  font-weight: 500;
  &:disabled, .disabled, .is-disabled {
    border: 0 !important;
    cursor: not-allowed !important;
  }
}

.button-rounded {
  border-radius: 2px;
}

.btn { outline: none !important; font-size: 15px;}
.btn-file { vertical-align: bottom; }

.button-dropdown-list > li > a {
  font-family: $montserrat;
  font-weight: 500;
}

.button-tiny  {
  font-size: 10px; padding: 0 10px;
  &.button-shrink { padding: 0 7px; }
}
.button-small {
  font-size: 12px; padding: 0 20px;
  &.button-shrink { padding: 0 13px; }
}
.button-large {
  font-size: 20px; padding: 0 40px;
  &.button-shrink { padding: 0 27px; }
}
.button-jumbo {
  font-size: 24px; padding: 0 50px;
  &.button-shrink { padding: 0 34px; }
}
.button-giant {
  font-size: 28px; padding: 0 60px;
  &.button-shrink { padding: 0 40px; }
}

.button-shrink { padding: 0 20px; }

.button:not(.button-theme):not(.button-primary):not(.button-action):not(.button-highlight):not(.button-royal):not(.button-caution):not(.button-plain):not(.button-inverse) {
  color: #666;
}

.button.disabled, .button.is-disabled, .button:disabled {
  border: 0 !important;
}

.button-border-white {
  transition: .25s background;
  color: #eee !important;
  span { transition: 0.25s right, 0.25s color !important; }
  &:hover {
    background: #fff;
    border-color: #fff;
    color: #222 !important;
    span { color: #222; }
  }
}

.button.disabled,
.button.is-disabled,
.button:disabled {
  opacity: 1 !important;
}

textarea:focus { outline:0 !important; }
select,
.popover { box-shadow: none; }

pre[class*="language-"] { margin: 0 !important; }
.pswp { display: none; }
.pswp--open { display: block !important; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
}

input {
  box-shadow: none;
  &:focus { outline: none; }
}

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: none;
}

p.content,
i.content {
  line-height: 1.75;
}

ul[class^='list'], ul[class*=' list'],
ol[class^='list'], ol[class*=' list'],
.dl-horizontal dd, .dl-horizontal dt {
  line-height: 1.75;
}

.form-control-label { padding-top: 7px; padding-bottom: 7px; margin-bottom: 0; }

.input-sm, .input-group-sm > .form-control, .input-group-sm > .input-group-btn > .btn {
  height: 32px !important;
  font-size: 12px !important;
  padding: 5px 16px !important;
}

.input-lg, .input-group-lg > .form-control, .input-group-lg > .input-group-addon, .input-group-lg > .input-group-btn > .btn {
  height: 47px !important;
  font-size: 18px !important;
}

.input-group-addon {
  border-radius: 2px;
}

.tt-menu {
  border-radius: 2px;
}

.fileinput-new.input-group .btn-file, .fileinput-new .input-group .btn-file {
  border: 0;
  transition: background .25s, color .25s;
  border-radius: 0 2px 2px 0;
}

.fileinput {
  line-height: 40px;
  &.input-group .form-control {
    line-height: 26px;
  }
  .fileinput-filename {
    margin-left: 5px;
  }
}

.form-control {
  box-shadow: none;
  border-radius: 2px;
  border: 1px solid #ccc;
  height: 40px;
  line-height: 40px;
  font-size: 15px;
  padding: 8px 16px;
  &:focus { box-shadow: none; }
}

textarea.form-control {
  line-height: 1.5;
}

.input-group-btn button {
  height: 40px;
}

@media screen and (min-width: $screen-sm) {
  .form-inline .form-group {
    vertical-align: top;
  }
}

.form-group textarea {
  display: block;
}


.dropcap:first-letter {
  float: left;
  font-size: 3rem;
  line-height: 0.9;
  margin-right: .5rem;
}

.panel {
  margin-bottom: 2px;
  box-shadow: none;
  border-radius: 3px;
}

.accordion-no-border .panel { border-color: transparent !important; }
.accordion-vertical { box-shadow: 0 0 0 1px #ddd !important; }
.well { box-shadow: none; }

.panel-theme .panel-heading {
  color: #fff;
}

.panel-heading {
  border-radius: 0;
  border-bottom: 0;
  position: relative;
  letter-spacing: .5px;
}

.accordion .panel-heading:after {
  font-family: 'fontawesome';
  content: '\f105';
  position: absolute;
  right: 20px;
  font-size: 20px;
  opacity: .75;
  top: 20px;
  line-height: 0;
}

.panel.acc-open .panel-heading {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  &:after {
    transform: rotate(90deg);
  }
}

.panel .panel-heading:after {
  transition: transform 0.25s;
  transform: rotate(0deg);
}

.panel-title {
  position: relative;
  line-height: 20px;
  font-size: 16px;
}

.accordion-combined {
  .panel {
    margin-bottom: 0;
    border-radius: 0;
    &:first-child { border-top-left-radius: 4px; border-top-right-radius: 4px; border-bottom: 0; }
    &:last-child { border-bottom-left-radius: 4px; border-bottom-right-radius: 4px; border-top: 0; }
  }
}

.accordion-no-color {
  .panel {
    border-color: #ddd;
  }
  .panel-heading {
    background: transparent !important;
    color: #444;
  }
}

.accordion-border .panel-heading { border-bottom: 1px solid #ddd; }

.av-inner { padding: 15px; }
.av-inner-media {
  padding: 0;
  height: 100%;
  video { height: 100%; }
  .av-image {
    height: 100%;
    background-size: cover;
    background-position-y: bottom;
  }
}

.liteAccordion.rounded .slide > h2 span {
  letter-spacing: 1px;
}

.liteAccordion.dark .av-inner {
  color: #bbb;
  a { color: #fff; }
}

.liteAccordion.light .slide > h2 {
  background: #f5f5f5;
  text-shadow: none;
  span {
    box-shadow: none;
    background: #ddd;
    color: #333;
    font-weight: 600;
  }
}

.liteAccordion.light .slide > h2.selected span,
.liteAccordion.light .slide h2.selected span:hover,
.liteAccordion.light .slide > h2.selected b {
  background-color: #ccc;
}


.liteAccordion .slide > h2 { z-index: 15; }
.liteAccordion.dark .slide > h2 { text-shadow: none; }

.liteAccordion.light .slide > div { background-color: #f5f5f5; }
.liteAccordion.light .slide > h2 { background-color: #f5f5f5; }
.liteAccordion.light { background-color: #f5f5f5; }
.liteAccordion.dark .slide > div { background-color: #111; }
.liteAccordion.dark .slide > h2 { background-color: #111; }
.liteAccordion.dark { background-color: #111; }

.list-circle { list-style-type: circle; }
.list-square { list-style-type: square; }
.list-group-item-heading { font-weight: 500; }

.has-error {
  .form-control {
    box-shadow: none;
    border-color: $red_form !important;
    &:focus {
      box-shadow: none;
      border-color: lighten($red_form, 15%) !important;
    }
  }
  .input-group-addon {
    color: $red_form;
    border-color: $red_form !important;
    background-color: lighten($red_form, 50%);
  }
}

.has-success {
  .form-control {
    box-shadow: none;
    border-color: $green_form !important;
    &:focus {
      box-shadow: none;
      border-color: lighten($green_form, 15%) !important;
    }
  }
  .input-group-addon {
    color: $green_form;
    border-color: $green_form !important;
    background-color: lighten($green_form, 50%);
  }
}

.has-warning {
  .form-control {
    box-shadow: none;
    border-color: $orange_form !important;
    &:focus {
      box-shadow: none;
      border-color: lighten($orange_form, 15%) !important;
    }
  }
  .input-group-addon {
    color: $orange_form;
    border-color: $orange_form !important;
    background-color: lighten($orange_form, 50%);
  }
}

h1, h2, h3, h4, h5, h6,
.h1,.h2,.h3,.h4,.h5,.h6 {
  font-family: $raleway;
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 600;
  &.slab {
    font-weight: 500;
  }
}

.slab-headings {
  h1, h2, h3, h4, h5, h6,
  .h1,.h2,.h3,.h4,.h5,.h6 {
    font-family: $oswald;
    font-weight: 500;
  }
}

h1 small, h1 .small, .h1 small, .h1 .small,
h2 small, h2 .small, .h2 small, .h2 .small,
h3 small, h3 .small, .h3 small, .h3 .small,
h4 small, h4 .small, .h4 small, .h4 .small,
h5 small, h5 .small, .h5 small, .h5 .small,
h6 small, h6 .small, .h6 small, .h6 .small {
  font-weight: 500;
}

hr {
  color: #aaa;
  border-top: 1px solid #eee;
}

hr.fa-divider {
  display: block;
  margin: 35px auto;
}

i.fa-divider-icon {
  position: relative;
  top: -52px;
  left: 9px;
  border-radius: 50%;
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 34px;
  text-align: center;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: #aaa;
}

hr.fa-divider-center + i {
  left: 0;
}
hr.fa-divider-right + i {
  left: -9px;
}
hr.fa-divider-rounded + i { border-radius: 4px; }
hr.fa-divider-square + i { border-radius: 0; }
hr.fa-divider-half {
  width: 500px;
  max-width: 100%;
  margin-left: 0;
}
hr.fa-divider-quarter {
  width: 250px;
  max-width: 100%;
  margin-left: 0;
}
hr.fa-divider-half,
hr.fa-divider-quarter {
  &.center {
    margin-left: auto;
    + i {
      left: 0;
    }
  }
  &.right {
    margin-left: auto;
    margin-right: 0;
    + i {
      left: -9px;
    }
  }
}
hr.fa-divider-big-icon + i {
  font-size: 1.75rem;
  width: 3.5rem;
  top: -60px;
  height: 3.5rem;
  line-height: 3.5rem;
}

hr.narrow {
  margin-top: 4px;
  margin-bottom: 6px;
}

.text-divider {
  position: relative;
  > span {
    position: absolute;
    top: -20px;
    background: #fff;
    padding: 0 25px 0 0;
  }
  &.text-center {
    > span {
      background: none !important;
      padding: 0;
      left: 0;
      right: 0;
      > span {
        background: #fff;
        padding: 0 25px;
      }
    }
  }
  &.text-right > span {
    right: 0;
    padding: 0 0 0 25px;
  }
}



// ###################
// ### breadcrumbs ###
// ###################

%breadcrumb-arrow-before {
  content: "";
  position: absolute;
  border-width: 20px 0 20px 16px;
  border-style: solid;
  border-color: #eee #eee #eee transparent;
  left: -16px;
}

%breadcrumb-arrow-after {
  content: "";
  position: absolute;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 16px solid #eee;
  right: -16px;
}

.breadcrumb-arrow,
.breadcrumb-arrow-solid {
  > li {
    position: relative;
    background: #eee;
    line-height: 40px;
    transition: all .25s;
    &:before { content: "" !important; }
    &.active {
      background: #ddd;
      &:before { border-color: #ddd #ddd #ddd transparent; }
      &:after { border-left: 16px solid #ddd; }
      &:hover, &:focus { background: #ddd; }
    }
    > a {
      padding-right: 8px;
      background: #eee;
      height: 40px;
      display: inline-block;
      &:before {
        transition: all .25s;
        @extend %breadcrumb-arrow-before;
      }
      &:after {
        transition: all .25s;
        @extend %breadcrumb-arrow-after;
      }
    }
    &:first-child {
      > a {
        padding: 0 10px 0 17px;
        &:before {
          left: 0;
        }
      }
    }
    &:last-child {
      padding: 0 8px 0 10px;
      &:before { @extend %breadcrumb-arrow-before; }
      &:after  { @extend %breadcrumb-arrow-after;  }
    }
    &:hover, &:focus {
      background: #ccc;
      > a {
        background: #ccc;
        color: #000;
        &:before { border-color: #ccc #ccc #ccc transparent; }
        &:after { border-color: transparent transparent transparent #ccc; }
      }
    }
  }
}

.breadcrumb-arrow       > li { margin: 0 8px; }
.breadcrumb-arrow-solid > li { margin: 0 6px; }

.breadcrumb-arrow-small {
  > li {
    margin: 0 5px;
    line-height: 30px;
    &:before { border-width: 15px 0 15px 10px !important; }
    &:after {
      border-top: 15px solid transparent !important;
      border-bottom: 15px solid transparent !important;
      border-left-width: 10px !important;
      right: -10px !important;
    }
    > a {
      height: 30px;
      &:before { border-width: 15px 0 15px 10px !important; left: -10px; }
      &:after {
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-left: 10px solid #eee;
        right: -10px;
      }
    }
    &:last-child:before { left: -10px; }
  }
  &.breadcrumb-arrow-solid > li { margin: 0 3px; }
}

.breadcrumb-arrow-rounded {
  li:first-child,
  li:first-child a,
  li:first-child a:before {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
}

.breadcrumb-arrow-rounded li:last-child,
.breadcrumb-arrow-rounded li:last-child a {
  padding: 0 16px 0 10px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  &:after { border: none; }
}




.breadcrumb {
  padding: 1rem 0;
  border-radius: 2px;
  background-color: transparent;
  margin-top: 20px;
  font-size: 13px;
  a {
    color: #777;
    &:hover {
      color: #000;
    }
  }
  > li + li:before {
    color: #777 !important;
  }
  &.text-right {
    padding-left: 0;
    padding-right: 15px;
  }
  .active { color: #000; }
}

.breadcrumb-justify {
  text-align: justify;
  &:after {
    width: 100%;
    display: inline-block;
    content: ".";
    visibility: hidden;
  }
}

.breadcrumb-raquo > li + li:before {
  content: "»";
  font-size: 16px;
  padding: 0 10px 0 6px;
}

.breadcrumb-pill > li {
  padding-right: 5px;
  &:before {
    content: "" !important;
    padding: 0 !important;
  }
  a {
    padding: 7px 15px;
    border-radius: 200px;
    background: #eee;
    color: #444;
  }
  &.active {
    padding: 5px 15px;
    border-radius: 200px;
    background: #ddd;
  }
  &:hover a {
    background: #ccc;
    color: #000;
  }
}

.breadcrumb-arrow,
.breadcrumb-arrow-solid,
.breadcrumb-pill {
  background-color: transparent !important;
}

// ###################
// ### blockquotes ###
// ###################

blockquote {
  border-left: 5px solid rgba(0,0,0,0.1);
  font-size: 15.5px;
  footer, small, .small {
    line-height: 1.5;
    color: #888;
  }
}
.blockquote-filled {
  border-radius: 2px;
  border-left: 0 !important;
  padding: 25px 35px;
  p { color: #333; }
  small {
    color: #333;
    font-weight: 600;
  }
}
.blockquote-filled-bw {
  border-radius: 2px;
  border-left: 0 !important;
  background-color: #444;
  color: #eee;
  padding: 25px 35px;
  small { color: #fff; }
}
.blockquote-marks {
  border-left: 0 !important;
  &:before {
    content: '“';
    font-size: 7rem;
    position: absolute;
    color: rgba(0,0,0,.1);
    top: -40px;
    left: 0;
  }
  &:after {
    content: '”';
    font-size: 7rem;
    position: absolute;
    color: rgba(0,0,0,.1);
    left: 160px;
    top: 65px;
  }
}


// ###################
// ### full screen ###
// ###################

.full-screen {
  height: 100vh;
  overflow: hidden;
  position: relative;

  background-size: cover !important;
  background-position: center !important;
  top: 0;
  > .container {
    top: 50%;
    position: relative;
    text-align: right;
    color: #ddd;
    transform: translateY(-50%);
  }
  &.full-screen-less {
    height: 75vh;
  }
}

.full-screen-text {
  top: 50%;
  transform: translateY(-50%);
  position: relative;
  padding: 20px 0 20px;
  &.third { top: 33vh; }
  &.two-thirds { top: 66vh; }
  &.bottom {
    top: auto;
    bottom: 0;
    transform: none;
    position: absolute;
    width: 100%;
  }
}

.full-screen-title-wrapper {
  text-align: center;
  color: #eee;
  padding: 0 50px;
  &.inverse {
    color: #111;
    &.solid-color {
      color: #111 !important;
    }
  }
  &.solid-color {
    color: #eee !important;
  }
}

.full-screen-shadow {
  top: -20px;
  text-align: center;
  user-select: none;
  cursor: default;
  z-index: -200;
  font-size: 50px;
  // big text-shadow blur will cause performance issues!
  text-shadow: 0 0 15px #000;
  color: transparent;
  position: absolute;
  width: 100%;
}
.full-screen-title {
  font-family: $montserrat;
  display: inline-block;
  font-weight: 500;
}
.full-screen-subtitle {
  font-weight: 400;
  font-size: 22px;
  font-family: $raleway;
  margin-bottom: 0;
  line-height: 1.7;
  display: inline-block;
}

.full-screen-container {
  padding: 50px 0;
  h3 {
    font-family: $montserrat;
    line-height: 1.5;
    margin-bottom: 0;
    font-weight: 500;
  }
}

.full-screen-links {
  float: right;
  margin-right: 100px;
  ul {
    line-height: 2.4;
  }
  a {
    color: #222 !important;
    &:hover {
      color: #111 !important;
    }
  }
}

.page-title {
  padding-top: 50px;
  padding-bottom: 15px;
  border-bottom: 1px dashed #ddd;
  position: relative;
  h1 {
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1px;
    margin-bottom: 1rem;
  }
  span {
    text-align: center;
    display: block;
    font-weight: 500;
    font-family: $raleway;
  }
  .breadcrumb { margin-top: 5px; }
  > .container {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
  }

  div.pull-left * {
    text-align: left;
  }
  div.pull-right * {
    text-align: right;
  }
  div.pull-left, div.pull-right {
    margin-bottom: 35px;
    *:first-child {
      margin-bottom: 7px;
    }
  }
}

.img-iconbox-fixed {
  .img-wrapper {
    max-height: 225px;
    overflow: hidden;
  }
}

.pt-clear-bg { height: 100px; }

@media screen and (max-width: $screen-sm-max) {
  .page-title {
    .pull-left, .pull-right {
      float: none !important;
      text-align: center;
      * { text-align: center !important; }
    }
  }
}

.pt-gradient {
  border: 0 !important;
  padding: 0;
  > .container {
    position: static;
    height: inherit;
    vertical-align: bottom;
    width: 100vw;
    display: table-cell;
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 25%,rgba(255,255,255,1) 100%);
  }
}

.pt-medium {
  height: 300px;
  background-size: cover;
  background-position: center;
  position: relative;
}

.pt-tall {
  height: 400px;
  background-size: cover;
  background-position: center;
  position: relative;
}
.video-pt-bg {
  overflow: hidden;
  video {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

.pt-narrow {
  padding: 15px;
  div.pull-left,
  div.pull-right {
    margin-bottom: 0;
  }
  .breadcrumb {
    margin-bottom: 0;
    margin-top: 10px;
  }
}


// ################
// ### feedback ###
// ################

.feedback-inline {
  .feedback-text{
    display: inline-block;
    vertical-align: middle;
    .author {
      margin-bottom: 0.5rem;
    }
  }
  .circle-img { display: inline-block; }
}

.circle-img {
  height: 10rem;
  display: inline-block;
  img {
    max-width: 100%;
    height: 100%;
    margin: 0 auto;
  }
}

.feedback-title { margin-bottom: 2rem; }
.feedback blockquote { border-left: 0 !important; line-height: 1.75; }

.feedback-bg {
  padding-top: 30px;
  padding-bottom: 30px;
  background-size: cover;
  background-position: center;
}

.feedback-grid {
  padding-left: 10px;
  padding-right: 10px;
  .circle-img {
    height: 5em;
    width: 5em;
    float: left;
    margin: 10px;
  }
  .feedback-text {
    blockquote {
      font-size: 1rem;
      margin-bottom: 5px;
    }
    .author {
      margin-bottom: 15px;
    }
  }
}

.feedback > .feedback-text,
.feedback-carousel .feedback-text {
  max-width: 767px;
  margin: auto auto 15px auto;
}

// #################
// ### copyright ###
// #################

.copyright { background: #fff; position: relative; }

.copyright-text {
  margin-bottom: 0;
  font-family: $lato;
  font-size: 15px;
}

.copyright-social {
  a { margin: 2px; }
}

@media screen and (max-width: $screen-xs-max) {
  .copyright-text { font-size: 16px; }
  .full-screen-container h3 {
    font-size: 18px;
  }
}

.copyright-nav {
  margin-bottom: 0;
  padding-left: 0;
  li {
    list-style-type: none;
    display: inline-block;
    margin-right: 13px;
    &:after {
      content: '/';
      position: relative;
      left: 8px;
    }
    &:last-child:after {
      content: '';
    }
  }
}

.copyright-nav-dashes li:after {
  content: '-';
}


// ##################
// ### pagescroll ###
// ##################

.section-slider {
  height: 100vh;
  overflow: hidden;
  z-index: 3;
  width: 100%;
}

// ##############
// ### mosaic ###
// ##############

.mosaic-image,
.mosaic-image-2,
.mosaic-image-3 {
  overflow: hidden;
  height: 100%;
  width: 100%;
  background-size: cover !important;
  img { width: 100%; }
}

.mosaic .row {
  margin: 0;
  & > div {
    padding: 0;
    overflow: hidden;
  }
}

.mosaic-text {
  padding: 40px;
  display: table-cell;
  vertical-align: middle;
}


@media screen and (min-width: $screen-md) and (max-width: $screen-md-max) {
  .mosaic-text {
    padding: 25px;
  }
}

.mosaic-wrapper {
  display: table;
  width: 100%;
  height: 100%;
}

.mosaic-inner {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  i {
    font-size: 3em;
    transition: color 0.25s;
    &:hover {
      cursor:pointer;
    }
  }
  a { cursor: default; }

}

.mosaic-inner-video {
  display: table-cell;
  iframe {
    width: 100%;
    height: 100%;
  }
  video {
    bottom: 0;
    position: absolute;
    min-height: 100%;
  }
}


// ##############
// ### device ###
// ##############

.device-image img { width: 220px; }


.device-left-side {
  .device-feature p { padding-right: 60px; }
  .device-icon { float: right; }
}

.device-right-side {
  .device-feature p { padding-left: 60px; }
  .device-icon { float: left; }
}

.device-long-title {
  .device-feature {
    margin-bottom: 50px;
    h4 {
      letter-spacing: .5px;
    }
  }
  .device-icon {
    transition: color 0.25s;
    i {
      font-size: 3rem;
      margin-top: 8px;
    }
  }
}

@media screen and (max-width: $screen-xs-max) {
  .device-long-title {
    .device-icon i { margin-top: 0; }
    .device-feature { margin-bottom: 3rem; }
  }
}

.device-small-image {
  position: absolute;
  width: 27% !important;
  bottom: 0;
  right: 0;
}

.device-slider {
  width: 220px;
  display: inline-block !important;
  .slick-track {
    padding-top: 0 !important;
    img {
      width: 220px !important;
    }
  }
}

.big-device-subject img { width: 100%; }
.big-device-subject-wrapper { z-index: 1; }

.big-device-text {
  line-height: 1.75;
}

.perspective-device-image img {
  width: 100%;
}

.perspective-device-text {
  font-size: 18px;
  font-family: $raleway;
  line-height: 1.75;
}

@media screen and (max-width: $screen-xs-max) {
  .perspective-device-image img {
    max-height: 400px;
    width: auto;
    margin: auto;
    display: block;
    max-width: 100%;
  }
  .perspective-device-text {
    font-size: 15px;
    font-weight: 400;
  }
}

// ############
// ### logo ###
// ############

.logo-wrapper {
  line-height: 58px;
  font-size: 50px;
  height: 60px;
  text-transform: none !important;
}

.logo-section {
  font-size: 20px;
  line-height: 0;
  display: block;
  font-family: $raleway;
  font-weight: 500;
}

.logo-section-small { font-size: 14px; }

.menu-navbar-collapse .text-logo { transform: scale(0.75); }

.text-logo {
  color: inherit !important;
  display: inline-block;
  transition: all linear 0.5s;
  text-align: center;
  &:hover,
  &:active,
  &:focus,
  &:visited {
    color: inherit !important;
  }
}

@media screen and (min-width: $screen-md) {
  .text-logo {
    position: absolute; // for smooth scaling
  }
  .navbar-items-left .text-logo { position: relative; }
  .navbar-items-left .text-logo { position: relative; }
}

.block-logo img {
  max-height: 250px;
  margin: 0 auto;
}

// ############
// ### edge ###
// ############

$edge-height: 42px;
.edge {
  height: $edge-height;
  .social-icons {
    position: relative;
    bottom: 6px;
    font-size: 0;
    margin: 0;
    a {
      height: $edge-height;
      &:hover i:before { color: #fff; }
      &:last-child { border-right: 1px solid #E7E7E7; }
    }
  }
}
.edge-social .social-icons a:last-child { border-right: 0 !important; }
.edge-left-block {
  @extend .edge;
  line-height: $edge-height;
  a {
    padding: 0 10px;
    white-space:nowrap;
  }
}
.edge-right-block {
  @extend .edge;
  line-height: $edge-height;
}

.footer ~ .edge .edge-left-block { margin-bottom: 0;}

.edge-button { margin: 0 5px; font-size: 16px; }

.edge-links {
  .edge-left-block a,
  .edge-right-block a {
    padding: 0 10px;
    border-right: 1px solid #E7E7E7;
    margin: 0;
  }
  .edge-left-block a {
    float: left;
    &:last-child { border-right: 0 !important; }
  }
  .edge-right-block a {
    float: right;
    &:first-child { border-right: 0 !important; }
  }
}

@media screen and (max-width: $screen-sm-max) {
  .edge { height: $edge-height*2; }
  .edge-left-block { text-align: center; height: $edge-height }
  .edge-right-block { text-align: center; height: $edge-height }
  .edge-links .edge-left-block a, .edge-links .edge-right-block a { float: none; }
  .edge-links .edge-right-block a {
    &:first-child { border-right: 1px solid #E7E7E7 !important; }
    &:last-child { border-right: 0 !important; }
  }
}

@media screen and (max-width: 450px) {
  .edge-left-block {
    font-size: 15px;
    padding: 0;
    a { padding: 0; }
  }
  .edge-right-block {
    > a:nth-of-type(1) {
      top: 0;
      position: absolute;
      line-height: initial;
    }
    > a:nth-of-type(2) {
      top: 12px;
      position: relative;
      line-height: initial;
    }
  }
}


// ############
// ### tabs ###
// ############

.dropdown-menu {
  font-size: 15px;
}

.nav-pills > li > a,
.dropdown-menu {
  border-radius: 3px;
}

.nav-tabs > li > a {
  border-radius: 3px 3px 0 0;
}

.nav-tabs {
  &.tabs-right li {
    a { border-radius: 0 3px 3px 0; }
    &.active {
      a, a:hover, a:focus {
        border: 1px solid;
        border-color: #ddd #ddd #ddd #fff;
        background-color: transparent;
      }
    }
  }
  &.tabs-left li {
    a { border-radius: 3px 0 0 3px; }
    &.active {
      a, a:hover, a:focus {
        border-radius: 3px 0 0 3px;
        border: 1px solid;
        border-color: #ddd #fff #ddd #ddd;
        background-color: transparent;
      }
    }
  }
  &.nav-border-top li {
    a, a:hover, a:focus {
      border-radius: 0;
    }
    &.active {
      a, a:hover, a:focus {
        border-top-color: transparent;
      }
    }
  }
  &.nav-border-bottom li {
    a, a:hover, a:focus {
      border-radius: 0;
      border-color: transparent;
    }
  }
}

.nav-tabs > li > a:hover { border-color: #ddd #ddd #ddd; }

.side-icons {
  .side-tab {
    border: 0;
    text-align: center;
    li {
      margin-bottom: 0;
      margin-right: 0;
      display: inline-block;
      width: 100%;
      a {
        border: 0 !important;
        border-radius: 0 !important;
        text-align: center;
        padding: 0;
        color: #222;
        &:hover { color: #222; }
        i {
          font-size: 4em;
          padding: 15px;
          transition: 0.25s all;
        }
      }
      &.active a i {
        &:hover,
        &:focus {
          background: transparent;
        }
      }
    }
  }

  .nav-tabs > li.active > a,
  .nav-tabs > li.active > a:hover,
  .nav-tabs > li.active > a:focus {
    background-color: transparent !important;
  }
}


@media screen and (max-width: $screen-sm-max) {
  .side-icons .side-tab li {
    width: auto;
    float: none;
  }
  .side-tabs .side-tab li {
    text-align: center;
  }
}

.tab-content { text-align: justify; }

.side-tabs-styled {
  ul {
    padding-top: 0;
    li {
      margin-bottom: 0 !important;
      transition: all 0.3s;
      a {
        border-radius: 0 !important;
        border-color: transparent !important;
      }
    }
  }
  .tabs-left li {
    margin-right: 0;
  }
  .tabs-right li {
    margin-left: 0;
  }
}

.tab-pane {
  > p:only-child {
    padding: 20px;
    margin-bottom: 0;
  }
  .blog-single-post-comments p {
    padding: 0;
  }
  .team p {
    padding: 0;
    margin: 0 0 10px;
  }
  .user-main-info p {
    padding: 0;
  }
  .widget {
    padding-top: 20px !important;
    padding-bottom: 20px;
  }
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: #000;
}

.tabs-filled {
  &.nav-tabs > li > a {
    margin-right: -1px;
    border-radius: 0;
    background: #eee;
    border: 1px solid #ddd;
  }
  &.nav-tabs > li.active > a,
  &.nav-tabs > li.active > a:hover,
  &.nav-tabs > li.active > a:focus {
    background-color: #fff;
    border-bottom-color: transparent;
  }
}

.tabs-center.nav-tabs {
  text-align: center;
  > li {
    float: none;
    display: inline-block;
    margin-right: -3px;
    a { border-right: 0 !important; }
    &:last-child a { border-right: 1px solid #ddd !important; }
  }
}

.tabs-a-right.nav-tabs {
  text-align: right;
  border-left: none;
  > li {
    float: none;
    display: inline-block;
    margin-right: -4px;
    &:last-child { margin-right: 1px; }
  }
}

.tabs-border {
  border-bottom: 0;
  ~ .tab-content {
    border: 1px solid #ddd;
  }
}

@media screen and (max-width: $screen-xs-max) {
  .tabs-left { border-right: 0 !important; }
  .tabs-right { border-left: 0 !important; }
  .side-tabs .tab-content { margin: 10px auto; }
}


// #####################
// ### image overlay ###
// #####################

.overlay-part {
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,.75);
  display: table;
}

.image-overlay-text {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  color: rgba(255,255,255,.9);
  p { color: rgba(255,255,255,.9); }
}

.image-overlay-title {
  margin-bottom: 1rem;
}

.image-overlay-gradient .overlay-part     { background: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)); }
.image-overlay-gradient-top .overlay-part { background: linear-gradient(to top,    rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)); }

.image-overlay-left {
  .image-overlay-text {
    padding-right: 50%;
    padding-left: 30px;
  }
  &.image-overlay-gradient {
    .overlay-part {
      background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
      width: 100%;
    }
  }
}

.image-overlay-right {
  .overlay-part { margin-left: 50%; }
  &.image-overlay-gradient {
    .overlay-part {
      margin-left: 0;
      background: linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    }
    .image-overlay-text {
      padding-left: 50%;
      padding-right: 30px;
    }
  }
}

.image-overlay-block  {
  background-size: cover;
  background-position: center !important;
}

@media screen and (max-width: $screen-xs-max) {
  .image-overlay-block {
    height: auto;
    .overlay-part {
      padding: 45px 0;
    }
  }
}


// ################
// ### headings ###
// ################

.fw-heading {
  display: table;
  width: 100%;
  color: #222;
}

.fw-heading-content {
  display: table-cell;
  vertical-align: middle;
}

.fw-heading-bg .fw-heading-text {
  background-color: rgba(255,255,255,.5);
  margin-bottom: 0;
  + p {
    background-color: rgba(255, 255, 255, 0.5);
    padding-bottom: 20px;
  }
}

.fw-heading-text + p {
  font-size: 16px;
}

.fw-heading-text span {
  display: block;
  font-size: 1.5rem;
}

.fw-button-text,
.fw-heading-text {
  padding: 15px;
  font-weight: 300;
  span.strong {
    font-weight: 600;
    border-color: transparent !important;
    transition: none;
  }
}

.fw-button-text {
  &:not(.fw-button-no-anim):hover {
    i { margin-left: 10px; }
    span { border-bottom: 1px solid rgba(0,0,0,.3); }
  }
  span { border-bottom: 1px solid rgba(0,0,0,0); }
  i, span { transition: all .25s; }
}

.fw-heading-bg,
.fw-button-bg,
.social-icons-fw-image,
.block-counter-multiple-bg,
.block-counter-single-img-multiple {
  background-size: cover;
  background-position: center;
}

.fw-grayscale {
  background: #ccc;
}

.heading-sub {
  .heading-main {
    margin-bottom: .5rem;
    border-bottom: 1px solid #ddd;
    display: inline-block;
    padding-bottom: .5rem;
  }
}

.heading-border,
.heading-line,
.heading-sub,
.heading-zig-zag {
  margin-bottom: 2rem;
}

.heading-border .heading-main {
  margin-bottom: .5rem;
  border-bottom: 1px solid #ddd;
  padding-bottom: .5rem;
}

.heading-zig-zag {
  background: url("/img/app/zig-zag.png") 0 45% repeat-x;
  .heading-main {
    display: inline-block;
    background: #fff;
    padding: 0 25px;
  }
}

.heading-line {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    border-top: 1px solid #ddd;
    top: 45%;
    right: 0;
    z-index: -2;
  }
  .heading-main {
    display: inline-block;
    background: #fff;
    padding: 0 25px;
  }
}

.fw-button {
  display: table;
  width: 100%;
  a {
    width: 100%;
    min-height: inherit;
    display: table-cell;
    vertical-align: middle;
    color: #111;
  }
}

.fw-button-theme a {
  color: #222;
  transition: none;
  &:hover, &:focus {
    color: #000 !important;
  }
}

.fw-button-theme-alt a {
  &:hover,
  &:focus {
    i { color: #fff; }
    span { border-bottom: 1px solid rgba(255,255,255,.3); color: #fff !important; }
  }
}

.fw-button-theme-inverse a {
  color: #eee;
  &:hover, &:focus {
    i { color: #fff; }
    span { border-bottom: 1px solid rgba(255,255,255,.3); color: #fff !important; }
  }
}

.fw-button-theme-inverse-alt a {
  color: #eee;
  &:hover, &:focus {
    color: #000 !important;
  }
}

.fw-button-gray-to-theme,
.fw-button-gray-to-theme-alt {
  background: #444;
  &:hover {
    span {
      border-bottom: transparent !important;
    }
  }
}

.fw-button-gray-to-theme a {
  color: #eee;
}

.heading-alt {
  margin-bottom: 30px;
  .heading-main {
    text-transform: uppercase;
  }
  p {
    font-size: 17px;
    font-weight: 300;
    font-family: $roboto;
    color: #888;
  }
  &:after {
    width: 50px;
    height: 2px;
    background: #555;
    content: '';
    display: block;
    position: relative;
    top: 2px;
  }
}

.heading-alt-2 {
  margin-bottom: 30px;
  .heading-main {
    font-family: $roboto;
    font-weight: 300;
    margin-bottom: 15px;
  }
  &:after {
    content: '';
    display: block;
    height: 2px;
    background: #555;
    width: 50px;
    top: 15px;
    position: relative;
  }
  &.no-line:after {
    display: none;
  }
}

.heading-alt, .heading-alt-2 {
  &.text-center:after {
    margin: auto;
  }
  &.text-right:after {
    margin-left: auto;
  }
}


// #################
// ### countdown ###
// #################

.countdown-block-img .countdown { background-color: rgba(255,255,255,0.5); }

.countdown-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.countdown-block {
  display: table;
  width: 100%;
}

.countdown-block-img { background-size: cover; }

.countdown-chunk {
  display: inline-block;
  margin: auto 1rem;
  .number {
    display: block;
    font-size: 6rem;
    position: relative;
    &:after {
      content: '';
      border-bottom: 1px solid #888;
      display: block;
      width: 35px;
      margin: auto;
      position: relative;
      bottom: 6px;
    }
  }
  .word {
    font-size: 1.5rem;
  }
}

.countdown-alt { font-size: 3.5rem; }

.countdown-border {
  .countdown-chunk {
    width: 200px;
    height: 200px;
    padding-top: 12px;
    margin: 1rem .5rem auto .5rem;
    border: 1px solid #444;
  }
  &.countdown-small .countdown-chunk {
    width: 150px;
    height: 150px;
    padding-top: 15px;
  }
  &.countdown-tiny .countdown-chunk {
    width: 100px;
    height: 100px;
    padding-top: 17px;
  }
}

.countdown-circle .countdown-chunk { border-radius: 200px; }
.countdown-rounded .countdown-chunk { border-radius: 6px; }

.countdown-small {
  .number {
    font-size: 4rem;
    &:after {
      bottom: 4px;
      width: 30px;
    }
  }
  .word { font-size: 1.25rem; }
  .countdown-alt { font-size: 2.5rem; }
}

.countdown-tiny {
  .number {
    font-size: 2rem;
    &:after {
      bottom: 1px;
      width: 20px;
    }
  }
  .word { font-size: 1rem; }
  .countdown-alt { font-size: 1.5rem; }
}

.countdown-vertical .countdown-chunk {
  margin: auto 3rem;
  .number:after {
    border-bottom: 0;
    border-right: 1px solid #888;
    height: 100%;
    right: -3rem;
    bottom: -24%;
    position: absolute;
  }
  &:last-child .number:after {
    border-right: 0;
  }
}


// ###########
// ### map ###
// ###########

.map-embed {
  overflow: hidden;
  width: 100%;
  height: 375px;
}

.map-embed-canvas {
  width: 100%;
  height: 100%;
  img {
    max-width: none !important;
    background: none !important;
  }
}

.map-prevent-scroll + .map-canvas { box-shadow: 0 4px 10px #000; }

.map-canvas {
  height: 100%;
  min-height: 528px;
  transition: all 0.25s;
}

.map-canvas-wrapper {
  height: 100%;
  min-height: 528px;
  transition: all 0.25s;
  top: 0;
  position: relative;
  &.small {
    min-height: 250px;
    .map-canvas { min-height: 250px; }
  }
}

.map-prevent-scroll {
  width: 100%; // if 30, there is small offset
  height: 100%;
  position: absolute;
  z-index: 19;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.map-fullscreen { height: 100vh; }

// #################
// ### subscribe ###
// #################

.subscribe form,
.coming-soon form,
.password-recovery form,
._404-input form {
  display: table;
  margin: 0 auto;
}

._404 ._404-input {
  max-width: 560px;
  width: 100%;
}

.password-recovery {
  max-width: 500px;
}

.subscribe .input-group,
.coming-soon .input-group {
  max-width: 400px;
  width: 100%;
}

.subscribe-title {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 19px;
  margin-bottom: 10px;
}

// ######################
// ### vertical image ###
// ######################

.vertical-images .row > div {
  height: 100%;
  & > div {
    background-position-x: 15%;
    height: 100%;
  }
}

.vertical-images, .vertical-images .row:only-child {
  height: 750px;
}

.vertical-images-overlay,
.horizontal-image-overlay {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.0);
  transition: all 0.25s;
  z-index: 5;
}

@media screen and (max-width: $screen-xs-max) {
  .vertical-images, .vertical-images .row:only-child {
    height: 550px;
  }
}


// ########################
// ### horizontal image ###
// ########################


@media screen and (min-width: $screen-md) {
  .horizontal-image-info {
    display: table;
    height: 100%;
    > div {
      display: table-cell;
      vertical-align: middle;
    }
  }
  .horizontal-image-right .horizontal-image-info {
    padding-left: 30px;
  }
  .horizontal-image-left .horizontal-image-info {
    padding-right: 30px;
  }
}

@media screen and (max-width: $screen-sm-max) {
  .horizontal-image-info {
    min-height: auto;
    .horizontal-image-title {
      margin-top: 0;
    }
  }
  .horizontal-image-right .horizontal-image-info {
    margin-bottom: 30px;
  }
  .horizontal-image-left .horizontal-image-info {
    margin-top: 30px;
  }
  .horizontal-image-title {
    display: inline-block;
    margin-top: 1rem;
  }
}

.horizontal-img-bg {
  background-position-y: 15%;
  background-size: 100%;
  height: 100%;
}



// ######################
// ### login register ###
// ######################

.auth-bottom-switcher {
  font-size: 16px;
  .fa {
    margin-right: 5px;
  }
}

.auth-tabs {
  .nav-pills > li {
    float: none;
    display: inline-block;
  }
  .auth-block-wrapper {
    padding: 0;
  }
}



.form-group:last-child { margin-bottom: 0; }

.full-screen-auth {
  background-size: cover;
  display: table;
  width: 100%;
  .auth-block-wrapper hr {
    border-top: 1px solid rgba(255,255,255,.35) !important;
  }
}

.auth-block {
  margin: 0 auto;
  max-width: 450px;
  background-color: rgba(0, 0, 0, 0.35);
  padding: 35px;
  box-shadow: 0 3px 10px #000;
  border-radius: 4px;
  color: #fff;
  label {
    font-weight: normal;
  }
  .forgot-pass {
    color: #bbb;
    &:hover, &:focus {
      color: #fff;
    }
  }
  a.link {
    color: #fff
  }
}

.auth-block-steps.auth-block-wrapper .forgot-pass {
  right: -10px;
}

@media screen and (max-width: 372px) {
  .auth-block-wrapper {
    max-width: 100vw;
  }
}

.auth-block-wrapper {
  display: table-cell;
  vertical-align: middle;
  padding: 70px 0;
  .auth-buttons {
    position: relative;
    button {
      padding: 0 45px;
      text-transform: uppercase;
    }
  }
  .forgot-pass {
    position: absolute;
    bottom: 10px;
    right: 0;
  }
  &.auth-simple {
    margin: 50px auto;
    display: block;
  }
  &.login-wrapper {
    max-width: 375px;
  }
  &.register-wrapper {
    max-width: 800px;
    .auth-buttons {
      text-align: center;
    }
  }
}

@media screen and (max-width: 500px) {
  .auth-block-wrapper .forgot-pass {
    position: static;
    margin-top: 30px;
    display: block;
  }
}

// ################
// ###  Pages   ###
// ################

._500 {
  display: table;
  width: 100%;
  padding: 50px 0;
}

._500-fh {
  height: 100vh;
}

._500_inner {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

._500_title {
  font-size: 10rem;
  margin-bottom: 0;
}

._500_lead {
  font-size: 40px;
}

._500_bottom {
  font-size: 16px;
  margin-bottom: 25px;
}

._404-color-bars {
  height: 100vh;
  display: table;
  margin: 0 auto;
  padding: 40px;
}

._404-color-bars-wrapper-inner {
  display: table-cell;
  vertical-align: middle;
}

._404-color-bars-inner {
  padding: 20px;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  background: rgba(255,255,255,.9);
  a {
    font-weight: bold;
    font-size: 16px;
  }
}

._404-background-video-inner {
  ._404-title,
  ._404_lead,
  ._404_bottom {
    color: #eee;
  }
}

.error-code {
  font-family: $roboto;
}

._404-fuzz {
  height: 100vh;
  width: 100%;
  display: table;
  position: fixed;
  left: 0;
}

._404-fuzz-inner {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  ._404-title {
    color: #222;
    background: rgba(0, 0, 0, 0.2);
    margin-bottom: 0;
    padding-top: 15px;
    padding-bottom: 5px;
  }
  ._404-link-wrapper {
    background: rgba(0, 0, 0, 0.2);
    padding-bottom: 25px;
    font-family: $roboto;
    span { color: #000; }
  }
}


.coming-soon-inner {
  display: inline-block;
  padding: 35px;
  background-color: rgba(255,255,255,.4);
  border-radius: 4px;
  box-shadow: 0 4px 10px #000;
  color: #252525;
}

.coming-soon-timer .countdown {
  background-color: transparent;
  font-size: 30px;
  padding: 0;
}

._404 ._404-title {
  font-size: 15rem;
  font-weight: 400;
  font-family: $lato;
}

@media screen and (max-width: $screen-tn-max) {
  ._404 ._404-title {
    font-size: 10rem;
  }
  .full-screen-title-wrapper {
    padding: 0 40px;
  }
}

._404_lead {
  font-size: 30px;
  margin-top: 35px;
}

._404_bottom {
  font-size: 17px;
}


.coming-soon-fullscreen,
._404-icon-block {
  height: 100vh;
  width: 100%;
  display: table;
}

.coming-soon-wrapper,
._404-icon-block-inner {
  display: table-cell;
  vertical-align: middle;
  a { display: inline-block; }
}

.coming-soon-timer-subtitle { margin-top: 3rem; }
.coming-soon-timer  { background-size: cover; }

@media screen and (max-width: $screen-xs-max) {
  .coming-soon-inner { padding: 35px 15px; }
  .coming-soon-timer-subtitle { margin-top: 2rem !important; }
  .input-group-btn:last-child {
    input {
      width: 100%;
      border-bottom-left-radius: 2px !important;
      margin-left: 0 !important;
    }
  }
  input.form-control {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px !important;
    margin-left: 0 !important;
    width: 100%;
  }
  .image-overlay-text {
    padding: 0 5px !important;
  }
}

.coming-soon-wrapper { padding: 65px 50px 50px; }

@media screen and (max-width: $screen-xs-max) {
  .coming-soon-wrapper { padding: 0; }
}

.coming-soon-simple-inner {
  hr {
    width: 50%;
    min-width: 500px;
  }
}

.coming-soon-logo {
  width: 255px;
  margin: 0 auto;
  img {
    width: 100%;
  }
}

.coming-soon-icon,
._404-icon-block-icon {
  display: inline-block;
  font-size: 10em;
}

.maintenance-display {
  font-size: 4rem;
  font-weight: 600;
  text-transform: uppercase;
}

.maintenance-lead {
  font-weight: 400;
  font-family: $raleway;
  font-size: 2rem;
}

.coming-soon .progress-label {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  font-family: $raleway;
}

.under-construction-sorry {
  transition: all 0.75s;
}

.under-construction-gif {
  margin-bottom: .5rem;
  max-width: 500px;
}


// #################################
// ### carousel / slider / slick ###
// #################################

.slick-slide[tabindex] {
  outline: none;
}

.carousel .slick-slide[tabindex] {
  height: 120px;
  text-align: center;
  opacity: .75;
}

.carousel-itself .item img,
.carousel-small .item img {
  cursor: move;
  height: 100%;
  display: inline-block;
  opacity: .75;
}

.carousel-itself .slick-track { margin-bottom: 20px; }

.slick-prev:before,
.slick-next:before {
  font-family: 'fontawesome';
  font-size: 2rem;
}

.slick-arrow {
  height: 50px;
  background: rgba(0,0,0,.3);
  width: 35px;
  z-index: 5;
  opacity: 0;
  transition: opacity .25s, background .25s;
}

.slick-prev {
  left: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.slick-next {
  right: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.slick-prev:before { content: '\f104'; }
.slick-next:before { content: '\f105'; }

.slick-prev:focus,
.slick-next:focus {
  background: rgba(0,0,0,.3);
}

.slick-prev:hover,
.slick-next:hover {
  background: rgba(0,0,0,.6);
}

.slick-slider:hover .slick-arrow {
  opacity: 1;
}

.video-carousel {
  .slick-track {
    height: 300px;
  }
  .slick-slide {
    height: inherit;
    overflow: hidden;
    img {
      height: inherit;
      cursor: pointer;
    }
    iframe {
      height: inherit;
      width: 100%;
    }
  }
}

.team-carousel {
  .slick-track {
    height: 280px;
  }
  .slick-slide {
    text-align: center;
  }
  .img-wrapper {
    height: 150px;
    overflow: hidden;
    border-radius: 200px;
    display: inline-block;
    width: 150px;
    img {
      width: inherit;
    }
  }
  .member-description {
    margin-top: 20px;
    .post {
      font-size: 16px;
      margin-top: 10px;
    }
  }
}

.portfolio-carousel,
.products-carousel {
  .gallery {
    height: initial !important;
    > .slick-list {
      margin: auto 40px;
    }
  }
}



.portfolio .portfolio-carousel {
  .slick-prev, .slick-next {
    top: 100px;
  }
}

.container .portfolio-carousel:not(.portfolio-no-padding) {
  .slick-prev, .slick-next {
    top: 130px;
  }
}

.blog-carousel {
  .slick-prev, .slick-next {
    top: 115px;
  }
}

.container .portfolio-carousel-itself,
.blog-carousel-itself,
.products-carousel-itself,
.team-carousel-itself {
  > .slick-prev,
  > .slick-next {
    border-radius: 3px;
  }
}

.blog-carousel-itself > .slick-list {
  margin: auto 30px;
}

.portfolio-carousel.portfolio-no-padding .gallery > .slick-list {
  margin: auto;
  .outer-block {
    padding-left: 20px !important;
  }
}

.gallery-carousel .lightbox {
  height: initial !important;
  .gallery-cell {
    max-height: 185px;
  }
}



.events-carousel {
  max-height: 180px;
  overflow: hidden;
  .event-info {
    height: initial;
    border: 0;
  }
  .event-name {
    font-weight: 700;
  }
  .event-description {
    vertical-align: initial;
  }
}

.blog-carousel {
  .blog-preview-text {
    padding-left: 0;
  }
  .blog-post {
    padding: 0 15px;
  }
  .blog-img-wrapper,
  .blog-media-wrapper {
    width: 100%;
    height: 200px;
  }
}

@media screen and (max-width: $screen-xs-max) {
  .video-carousel .slick-slide img {
    width: 100%;
    height: initial;
  }
  .video-carousel .slick-slide iframe {
    width: 100%;
    height: 300px;
  }
}

// ################
// ### progress ###
// ################

.clients-grid {
  .row {
    overflow: hidden;
  }
  .clients-grid-element {
    height: 120px;
    line-height: 120px;
    text-align: center;
    padding: 0;
    opacity: .5;
    transition: opacity .25s;
    img {
      max-width: 100%;
      max-height: 100%;
      padding: 20px;
    }
    &:hover {
      opacity: .75;
    }
    &:before {
      content: '';
      position: absolute;
      height: 100%;
      display: block;
      right: 0;
      border-right: 1px solid rgba(0,0,0,.1);
    }
    &:after {
      content: '';
      display: block;
      border-bottom: 1px solid rgba(0,0,0,.1);
      position: absolute;
      bottom: -1px;
      width: 100%;
    }
  }
  &.clients-grid-bg {
    background-size: cover;
  }
}

.clients-grid-4-col .clients-grid-element,
.clients-grid-3-col .clients-grid-element,
.clients-grid-2-col .clients-grid-element {
  height: 150px;
}

.clients-overlay {
  background: rgba(255,255,255,.75);
  color: #444;
}

.clients-overlay-inv {
  background: rgba(0,0,0,.75);
  color: #bbb;
  .slick-dots li button:before {
    color: #fff;
  }
}

// ################
// ### progress ###
// ################

.progress {
  box-shadow: none !important;
  border-radius: 3px;
  margin-bottom: 16px;
  span { font-size: 1rem; }
  &.progress-slim,
  &.progress-slim-2 {
    height: 3px;
    overflow: visible;
    .progress-bar {
      overflow: visible;
    }
  }
  &.progress-slim {
    .progress-label {
      position: absolute;
      right: -6px;
      background: #444;
      top: -30px;
      font-family: $montserrat;
      font-weight: 500;
      padding: 0 5px;
      border-radius: 2px;
      color: #fff;
      &:after {
        content: '';
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #444;
        bottom: -5px;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }
  &.progress-slim-2 {
    .progress-label {
      position: absolute;
      right: 0;
      top: -20px;
      font-weight: 500;
      font-family: $montserrat;
    }
  }
}

.progress-bar {
  box-shadow: none !important;
}

.progress-line-title {
  margin-bottom: 2px;
  font-family: $montserrat;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
}

.progress .progress-bar {
  transition: width 1s ease-in-out;
  border-radius: 0;
  position: relative;
  &:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  &:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}

.block-progress.progress-no-anim .progress .progress-bar {
  transition: width 0s;
}

.vertical-progress-wrapper {
  height: 300px;
  .progress {
    width: 40px;
    margin-right: 20px;
    display: inline-block;
    float: none;
  }
  .progress-bar {
    border-bottom-right-radius: 0;
    border-top-left-radius: 3px;
    span {
      margin-top: 3px;
      display: inline-block;
    }
  }
}

// ###############
// ### counter ###
// ###############

.block-counter-itself {
  font-size: 3.5rem;
  line-height: 3.5rem;
}

.block-counter-small .block-counter-itself {
  font-size: 2rem;
  line-height: 2rem;
}

.block-counter-large .block-counter-itself {
  font-size: 4.5rem;
  line-height: 4.5rem;
}

.block-counter-icon {
  font-size: 3rem;
  line-height: 3rem;
}

.block-counter-filled,
.block-counter-img {
  .block-counter-itself { color: #fff; }
  .block-counter-text { color: #fff; font-weight: 400; }
  .block-counter-icon { color: #fff; }
  .row > div {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.block-counter-filled,
.block-counter-img,
.block-counter-icons {
  .block-counter-itself { margin-top: 1rem; }
}

.block-counter-small {
  .block-counter-text { letter-spacing: 1px; }
}

.icon-box-counter {
  .icon-wrapper {
    float: left;
    font-size: 2rem;
    .icon {
      padding: 1rem;
      width: 2rem;
    }
  }
}

.icon-box-counter-center {
  .icon-wrapper {
    font-size: 2rem;
    .icon {
      padding: 1rem;
      width: 2rem;
    }
  }
}



.block-counter-text {
  font-weight: 500;
  margin-bottom: 4px;
  margin-top: 1rem;
}

.block-counter-images .row > div,
.block-counter-single-img .row > div { background-size: cover !important; }

// #############
// ### embed ###
// #############

.embed-block iframe {
  height: 375px;
  width: 100%;
}

@media screen and (max-width: $screen-sm-max) {
  .embed-block-title { margin-top: 1rem; }
}


// ###############
// ### welcome ###
// ###############


.bootstrap-select .dropdown-menu {
  border-radius: 3px;
  box-shadow: none;
  a { padding: 5px 20px; }
  & > li > a:hover,
  & > li > a:focus {
    background-color: #eee;
  }
}


.bootstrap-select.form-control {
  background-color: transparent;
  border: 0 !important;
}

.bootstrap-select .btn:focus {
  outline: none !important;
}

.dropdown-color {
  margin-bottom: 1rem;
  display: block;
}



.author {
  text-align: right;
  display: block;
  color: #888;
}

.author-name {
  &:after { content: ', ' }
  &:before { content: '— ' }
}


#big-video-image {
  width: initial !important;
  height: 100% !important;
}

.fp-tooltip { color: rgba(0, 0, 0, 0.7) !important; }

.sweet-alert {
  border-radius: 2px !important;
  p {
    font-family: $lato;
    font-weight: 400;
    color: #333;
    font-size: 15px;
  }
  .sa-input-error { top: 24px !important; }
}

.mosaic .row > div { height: 350px; }

.has-feedback label ~ .form-control-feedback {
  top: 29px;
}

.checkbox label:before {
  border-radius: 1px;
}

input[type="checkbox"]:hover {
  cursor: pointer;
}

.radio input[type="radio"] {
  cursor: pointer;
}

.checkbox input[type="radio"] {
  margin-left: -20px;
  + label:after {
    left: -0.5px;
  }
}

.checkbox input:not([disabled]) ~ label:hover:before,
.checkbox input:not([disabled]):hover ~ label:before,
.radio input:not([disabled]) ~ label:hover:before,
.radio input:not([disabled]):hover ~ label:before {
  background: rgba(0,0,0,.1);
}

.radio,
.checkbox {
  label:before {
    outline: none !important;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out, background 0.15s ease-in-out;
    top: 50%;
    margin-top: -8.5px;
  }
}

.radio input[type="radio"]:checked + label::after {
  top: 50%;
  margin-top: -5.5px;
}


.checkbox, .radio { padding-left: 17px; }

.checkbox label::after,
.radio label::after {
  top: 50% !important;
}

.radio label:after {
  margin-top: -5.5px;
}

.checkbox label::after {
  margin-top: -9.5px;
  left: 0.5px;
}

.checkbox label::after,
.checkbox label::before,
.radio label::before,
.radio label::after {
  margin-left: -17px;
}

.checkbox label,
.radio label {
  padding-left: 8px;
}

label + .help-block.with-errors {
  padding-left: 5px;
}

// #############
// ### other ###
// #############

.scrollToTop {
  right: 20px;
  bottom: 20px;
  font-size: 30px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  position: fixed;
  width: 38px;
  height: 38px;
  background: rgba(0,0,0,.35);
  line-height: 50px;
  border-radius: 200px;
  color: #fff;
  transition: background .25s;
  display: none;
  z-index: 2128506;
  &:hover {
    background: rgba(0,0,0,.5);
  }
}

// custom scroll-to-top buttons:

#scrollUp {
  transition: margin 0.25s;
  backface-visibility: hidden;
}

.scroll-up-image {
  opacity: 0.5;
  background-image: url($scroll-up-image-bg);
  bottom: 20px;
  right: 20px;
  width: 38px;
  height: 38px;
  &:hover { opacity: 1; }
}

.scroll-up-link {
  bottom: 20px;
  right: 20px;
  color: #111;
}

.scroll-up-pill {
  bottom: 20px;
  right: 20px;
  background-color: #555;
  color: #fff;
  text-decoration: none;
  opacity: .9;
  padding: 10px 20px;
  border-radius: 16px;
  transition: background 200ms linear;
  -webkit-backface-visibility: hidden;
  &:hover { background-color: #000; color: #fff; }
}

.scroll-up-tab {
  bottom: 0;
  right: 30px;
  width: 70px;
  height: 70px;
  margin-bottom: -10px;
  padding: 10px 5px;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 0 #fff;
  color: #828282;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  background: #E6E6E6 linear-gradient(to bottom, #EBEBEB, #DEDEDE) repeat-x;
  transition: margin-bottom 150ms linear;
  &:hover { margin-bottom: 0; }
}

iframe {
  border-width: 0;
}

.restaurant-menu {
  ul {
    text-align: left;
    font-family: $montserrat;
    margin: 0 0 17px;
    font-size: 16px;
    padding-left: 0;
    list-style: none;
    font-weight: 500;
  }
  .section {
    font-family: $raleway;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 15px;
  }
  .info {
    margin-left: 5px;
    font-size: 10px;
  }
  .price {
    float: right;
  }
  li {
    border-bottom: 1px solid rgba(0,0,0,.1);
    padding: 7px 0;
    &:last-child {
      border-bottom: 0;
    }
  }
}

.round-logo {
  height: 200px;
  width: 200px;
  border-radius: 200px;
  background: #0074D9;
  margin: auto;
  text-align: center;
  display: table;
  top: 50%;
  position: relative;
  transform: translateY(-50%);
  > div {
    vertical-align: middle;
    display: table-cell;
    color: #fff;
    text-transform: uppercase;
    font-family: $oswald;
    line-height: 1.1;
    > .top {
      font-size: 58px;
    }
    > .bottom {
      font-size: 21px;
    }
  }
}

.sidebar-filled {
  border-bottom: solid 5px #222;
  padding: 15px;
  color: #eee;
  .heading {
    text-transform: uppercase;
    font-family: $montserrat;
    font-size: 30px;
  }
  .text {
    font-family: $raleway;
    font-size: 20px;
    margin-bottom: 15px;
    line-height: 1.3;
  }
  .glink {
    color: #eee;
    font-size: 14px;
    font-family: $montserrat;
    i {
      margin-right: 3px;
      transition: margin .25s;
    }
    &:hover i {
      margin-right: 6px;
    }
  }
}

.contact-company-name {
  margin-bottom: 7px;
  display: inline-block;
  border-bottom: 1px solid #ddd;
}

.bg-video-block {
  max-height: 375px;
  overflow: hidden;
  position: relative;
  .bg-video-inner {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    width: 100%;
    color: #fff;
    &.color-inverse {
      color: #333;
    }
    .title {
      font-weight: 600;
      font-size: 40px;
      font-family: $raleway;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    .text {
      font-family: $raleway;
      margin-bottom: 25px;
      font-size: 16px;
    }
    i.fa {
      margin-right: 5px;
      font-size: 35px;
    }
    &.third {
      top: 33vh;
    }
    &.bottom {
      bottom: 0;
      top: auto;
      transform: none;
    }
  }
  video {
    width: 100%;
    display: block;
  }
  &.bg-video-narrow { max-height: 250px; }
  &.bg-video-tall  { max-height: 500px; }
  &.bg-video-full  { max-height: 100vh; }
}

@media screen and (max-width: $screen-xs-max) {
  .bg-video-block .bg-video-inner {
    .title { font-size: 24px; }
    .content { font-size: 15px; }
  }
  .mosaic .row > div { height: 500px; }
  .mosaic-text { padding: 15px; }

  .clients-grid-6-col,
  .clients-grid-5-col,
  .clients-grid-4-col,
  .clients-grid-3-col,
  .clients-grid-2-col {
    .clients-grid-element:nth-child(2n+2):before { border-right: none; }
  }

  .bg-video-block {
    max-height: initial !important;
    .bg-video-inner {
      position: relative;
      transform: none;
      color: #444;
      .title {
        margin-top: 15px;
      }
    }
  }
}

.single-project-video-extended {
  max-height: 550px;
  overflow: hidden;
  position: relative;
  .video-title {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    width: 100%;
    color: #fff;
    font-size: 40px;
    font-family: $raleway;
    font-weight: 300;
    i.fa {
      margin-right: 5px;
      font-size: 35px;
    }
  }
}

.rateit {
  display: block !important;
  margin-bottom: 9px;
}

div.rateit-range{
  background: url("/img/app/star-gray.png") !important;
}

div.rateit-hover,
div.rateit-selected {
  background: url("/img/app/star-gold.png") !important;
}

.dark-filter-line {
  background: #111;
}

.filter-line {
  > .clearfix { padding: 5px 0; }
  &:not(.dark-filter-line) .button.disabled {
    background: rgba(50,50,50,.15) !important;
  }
  &.dark-filter-line .button.disabled {
    background: #333 !important;
    opacity: 0.5 !important;
  }
  .button {
    padding: 0 20px;
    height: 30px;
    line-height: 30px;
    margin: 0 3px;
    letter-spacing: 0;
    &:first-child { margin-left: 0; }
    &:last-child { margin-right: 0; }
    &.disabled {
      text-shadow: none !important;
      pointer-events: none;
    }
  }
  .text {
    line-height: 40px;
    color: #fff;
    font-family: $raleway;
    letter-spacing: 1px;
    margin-bottom: 0;
    font-size: 20px;
  }
  .pull-right,
  .pull-left {
    line-height: 40px;
  }
  > .clearfix ~ .clearfix {
    padding: 0 0 5px 0;
    margin-top: -5px;
  }
  &.filter-double-line {
    .container {
      position: relative;
      .pull-left .text {
        position: absolute;
        line-height: 80px !important;
      }
      .pull-right .text {
        position: absolute;
        right: 0;
        line-height: 80px;
      }
    }
  }
  .portfolio-filter .button {
    transition: color .25s, background .25s !important;
  }
  .filter-line-item-dropdown {
    display: inline-block;
    > a:hover + .filter-line-dropdown {
      display: block;
    }
  }
  .filter-line-dropdown {
    display: none;
    position: absolute;
    list-style: none;
    margin-top: -4px;
    padding: 10px 0;
    min-width: 105px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    z-index: 2;
    font-family: $montserrat;
    font-size: 13px;
    font-weight: 500;
    li {
      min-width: inherit;
      line-height: 35px;
      a {
        padding: 0 25px;
        min-width: inherit;
        display: inline-block;
        color: #fff;
        width: 100%;
      }
    }
    &:hover {
      display: block;
    }
  }
  &.dark-filter-line .filter-line-dropdown {
    background: #222;
    li a:hover {
      background-color: lighten(#222, 10%);
      border-color: lighten(#222, 10%);
    }
  }
}

.portfolio-filter-line .button {
  text-transform: none;
  transition: all .25s !important;
}

.filter-line-portfolio-overlay .button {
  padding: 0;
  width: 30px;
}

@media screen and (min-width: $screen-md) and (max-width: $screen-md-max) {
  .filter-line.filter-double-line .container .pull-left .text {
    line-height: 115px !important;
  }
}

@media screen and (max-width: $screen-sm-max) {
  .filter-line {
    .pull-left, .pull-right {
      float: none !important;
      text-align: center;
    }
    .text { position: relative !important; }
    > .clearfix ~ .clearfix {
      padding-bottom: 25px;
    }
  }
}

.input-daterange .input-group-addon {
  border-width: 1px 0;
  border-color: #ccc;
}

[class^="hvr-"],
[class*=" hvr-"] {
  border-radius: 3px;
  &:before {
    border-radius: 3px;
    font-weight: 400;
  }
}

.apply-form-heading {
  text-align: center;
}
.apply-form {
  max-width: 600px;
  margin: auto;
  label {
    text-transform: uppercase;
    font-family: $montserrat;
    font-size: 14px;
    font-weight: 600;
  }
}


.team .team-member .social-icons { margin-bottom: 50px; }
.team.team-grid .team-member {
  margin-bottom: 30px;
}
.team.team-round {
  .team-member-img {
    border-radius: 200px;
    overflow: hidden;
    height: 200px;
    width: 200px;
    margin: auto;
    &.smaller {
      height: 150px;
      width: 150px;
    }
  }
}
.team-member-name {
  margin-top: 10px;
  font-size: 22px;
  font-family: $raleway;
  font-weight: 600;
  margin-bottom: 15px;
}
.team-member-post {
  margin-top: 15px;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
  color: #606060;
}
.team img { width: 100%; }


// switch buttons

.bootstrap-switch {
  border-color: transparent;
  font-weight: 500;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 1px #333 !important;
  border-radius: 1px;
  &.bootstrap-switch-focused {
    box-shadow: none;
  }
  .bootstrap-switch-handle-on.bootstrap-switch-theme,
  .bootstrap-switch-handle-off.bootstrap-switch-theme {
    color: #fff;
  }
}

.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-inverse.bootstrap-switch-off .bootstrap-switch-label {
  border-bottom-right-radius: 1px;
  border-top-right-radius: 1px;
}

.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-off,
.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-inverse.bootstrap-switch-on .bootstrap-switch-label {
  border-bottom-left-radius: 1px;
  border-top-left-radius: 1px;
}

.bootstrap-switch.bootstrap-switch-animate .bootstrap-switch-container {
  transition: margin-left 0.25s;
}

.fancy-switch {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
  + label {
    position: relative;
    cursor: pointer;
    outline: none;
    user-select: none;
    display: inline-block;
  }
}

.mb_YTPBar {
  z-index: 10 !important;
}

.pie-chart {
  position: relative;
  display: inline-block;
  width: 110px;
  height: 110px;
  line-height: 110px;
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: center;
  canvas {
    position: absolute;
    top: 0;
    left: 0;
  }
}

$tiny-height:   20px;
$tiny-width:    40px;
$small-height:  30px;
$small-width:   60px;
$medium-height: 40px;
$medium-width:  80px;
$large-height: 50px;
$large-width:  100px;
$huge-height:   60px;
$huge-width:    120px;

.fancy-switch-round {
  + label {
    padding: 2px;
    width: $medium-width;
    height: $medium-height;
    border-radius: $medium-height;
    background-color: #dddddd;
    &:before, &:after {
      display: block;
      position: absolute;
      top: 1px;
      left: 1px;
      bottom: 1px;
      content: "";
    }
    &:before {
      right: 1px;
      background-color: #f1f1f1;
      border-radius: $medium-height;
      transition: background 0.4s;
    }
    &:after {
      width: $medium-height - 2px;
      background-color: #fff;
      border-radius: 100%;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
      transition: margin 0.4s;
    }
  }
  &:checked + label:after {
    margin-left: $medium-height;
  }

  &.fancy-switch-tiny {
    + label {
      width: $tiny-width;
      height: $tiny-height;
      border-radius: $tiny-height;
      &:before {
        border-radius: $tiny-height;
      }
      &:after {
        width: $tiny-height - 2px;
      }
    }
    &:checked + label:after {
      margin-left: $tiny-height;
    }
  }
  &.fancy-switch-small {
    + label {
      width: $small-width;
      height: $small-height;
      border-radius: $small-height;
      &:before {
        border-radius: $small-height;
      }
      &:after {
        width: $small-height - 2px;
      }
    }
    &:checked + label:after {
      margin-left: $small-height;
    }
  }
  &.fancy-switch-large {
    + label {
      width: $large-width;
      height: $large-height;
      border-radius: $large-height;
      &:before {
        border-radius: $large-height;
      }
      &:after {
        width: $large-height - 2px;
      }
    }
    &:checked + label:after {
      margin-left: $large-height;
    }
  }
  &.fancy-switch-huge {
    + label {
      width: $huge-width;
      height: $huge-height;
      border-radius: $huge-height;
      &:before {
        border-radius: $huge-height;
      }
      &:after {
        width: $huge-height - 2px;
      }
    }
    &:checked + label:after {
      margin-left: $huge-height;
    }
  }
}


.fancy-switch-flat {
  + label {
    padding: 2px;
    width: $medium-width;
    height: $medium-height;
    background-color: #dddddd;
    border-radius: $medium-height;
    transition: background 0.4s;
    &:before, &:after {
      display: block;
      position: absolute;
      content: "";
    }
    &:before {
      top: 2px;
      left: 2px;
      bottom: 2px;
      right: 2px;
      background-color: #fff;
      border-radius: $medium-height;
      transition: background 0.4s;
    }
    &:after {
      top: 4px;
      left: 4px;
      bottom: 4px;
      width: $medium-height - 8px;
      background-color: #dddddd;
      border-radius: $medium-height - 8px;
      transition: margin 0.4s, background 0.4s;
    }
  }
  &:checked + label:after {
    margin-left: $medium-height;
  }

  &.fancy-switch-tiny {
    + label {
      width: $tiny-width;
      height: $tiny-height;
      border-radius: $tiny-height;
      &:before {
        border-radius: $tiny-height;
      }
      &:after {
        width: $tiny-height - 8px;
        border-radius: $tiny-height - 8px;
      }
    }
    &:checked + label:after {
      margin-left: $tiny-height;
    }
  }
  &.fancy-switch-small {
    + label {
      width: $small-width;
      height: $small-height;
      border-radius: $small-height;
      &:before {
        border-radius: $small-height;
      }
      &:after {
        width: $small-height - 8px;
        border-radius: $small-height - 8px;
      }
    }
    &:checked + label:after {
      margin-left: $small-height;
    }
  }
  &.fancy-switch-large {
    + label {
      width: $large-width;
      height: $large-height;
      border-radius: $large-height;
      &:before {
        border-radius: $large-height;
      }
      &:after {
        width: $large-height - 8px;
        border-radius: $large-height - 8px;
      }
    }
    &:checked + label:after {
      margin-left: $large-height;
    }
  }
  &.fancy-switch-huge {
    + label {
      width: $huge-width;
      height: $huge-height;
      border-radius: $huge-height;
      &:before {
        border-radius: $huge-height;
      }
      &:after {
        width: $huge-height - 8px;
        border-radius: $huge-height - 8px;
      }
    }
    &:checked + label:after {
      margin-left: $huge-height;
    }
  }
}

.fancy-switch-rotate {
  + label {
    padding: 2px;
    width: $medium-width;
    height: $medium-height;
    font-weight: 400;
    font-family: $lato;
    &:before, &:after {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      color: #fff;
      font-size: 18px;
      text-align: center;
      line-height: $medium-height;
    }
    &:before {
      background-color: #dddddd;
      content: attr(data-off);
      transition: transform 0.5s;
      backface-visibility: hidden;
    }
    &:after {
      content: attr(data-on);
      transition: transform 0.5s;
      transform: rotateY(180deg);
      backface-visibility: hidden;
    }
  }
  &:checked + label:before {
    transform: rotateY(180deg);
  }
  &:checked + label:after {
    transform: rotateY(0deg);
  }

  &.fancy-switch-tiny + label {
    width: $tiny-width;
    height: $tiny-height;
    &:before, &:after {
      line-height: $tiny-height;
      font-size: 10px;
    }
  }

  &.fancy-switch-small + label {
    width: $small-width;
    height: $small-height;
    &:before, &:after {
      line-height: $small-height;
      font-size: 14px;
    }
  }

  &.fancy-switch-large + label {
    width: $large-width;
    height: $large-height;
    &:before, &:after {
      line-height: $large-height;
      font-size: 22px;
    }
  }

  &.fancy-switch-huge + label {
    width: $huge-width;
    height: $huge-height;
    &:before, &:after {
      line-height: $huge-height;
      font-size: 26px;
    }
  }
}

// end of switch

.fileinput .close.fileinput-exists {
  top: 3px;
  position: relative;
  float: none;
}

.fileinput .thumbnail {
  display: table-cell;
  border-radius: 2px;
  + div { margin-top: 5px; }
}


.pagination .active > a {
  &:hover,
  &:focus {
    color: #fff !important;
  }
}


#input-slider-rectangle {
  .slider-handle    { background: $blue; }
  .slider-selection { background: lighten($blue, 30%); }
}
#input-slider-round {
  .slider-handle    { background: $green; }
  .slider-selection { background: lighten($green, 30%); }
}
#input-slider-square {
  .slider-handle    { background: $red;   }
  .slider-selection { background: lighten($red, 30%); }
}
#input-slider-triangle {
  .slider-selection { background: lighten($yellow, 30%); }
}

.ion-range { box-shadow: none !important; }
.irs-bar-edge {
  border-top-left-radius: 200px;
  border-bottom-left-radius: 200px;
}
.irs-slider {
  cursor: pointer;
  &:hover, &.state_hover {
    background-position: 0 -120px;
  }
}

.irs-line-left {
  background: #eee;
  border-bottom-left-radius: 200px;
  border-top-left-radius: 200px;
}

.irs-line-mid {
  background: #eee;
}

.irs-line-right {
  background: #eee;
  border-bottom-right-radius: 200px;
  border-top-right-radius: 200px;
}



.slider-handle,
.slider-selection,
.slider-track {
  background-image: none;
}

.slider-selection {
  box-shadow: none;
  background-color: #eee;
}

.slider-track {
  background-color: #eee;
}

.slider-handle.triangle {
  background: none !important;
}

.slider.slider-disabled {
  .slider-track {
    background: #eee none;
  }
  .slider-handle {
    background: #bbb none;
    box-shadow: none;
  }
}

.slider-handle.custom {
  background-color: transparent !important;
  box-shadow: none;
  &:before {
    line-height: 20px;
    font-size: 20px;
    content: '\2605';
    color: #726204;
  }
}


.select2-container { width: 100% !important; }
.select2-container--classic .select2-selection--single:focus,
.select2-container--classic .select2-selection--multiple:focus {
  border: none;
}
.select2-dropdown {
  border: 1px solid #ccc;
}
.select2-container--focus *:focus { outline: none !important; }
.select2-container--default .select2-search--inline .select2-search__field { box-shadow: none !important; }
.select2-container--focus:focus { outline: none !important; }
.select2-container--default.select2-container--focus .select2-selection--multiple { border: 1px solid #aaa; }
.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--multiple {
  border-radius: 2px;
  height: 34px;
  border: 1px solid #ccc;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding: 0 10px;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  padding: 6px 20px 6px 10px;
  line-height: 22px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 34px;
}

.rootwizard {
  > ul {
    text-align: justify;
    &:before {
      content: '';
      display: block;
      width: 100%;
      border-bottom: 1px dashed #ccc;
      position: relative;
      top: 25px;
    }
    &:after {
      content: '';
      display: inline-block;
      width: 100%;
    }
    > li {
      display: inline-block;
      float: none;
      text-align: center;
      span {
        margin-top: 10px;
        display: inline-block;
        transition: all .25s;
        font-weight: 500;
      }
      &.active {
        a { border: none; }
        span { transform: scale(1.25); }
        ~ li {
          color: #ccc;
          a {
            color: #ccc;
            border-color: #ccc;
          }
        }
      }
      > a {
        border-radius: 200px;
        width: 50px;
        height: 50px;
        text-align: center;
        line-height: 47px;
        padding: 0;
        font-size: 25px;
        background: #fff;
        border: 1px solid #777;
        color: #444;
        margin: 0 auto;
      }
    }
  }
  .nav-pills > li + li { margin-left: 0; }
  .tab-content {
    margin: 1.5rem auto;
    > .tab-pane { display: block; }
  }
  .pager > li > a {
    padding-top: 0;
    line-height: 40px;
    border-radius: 4px;
  }
}

.rootwizard-short > ul {
  min-width: 320px;
  width: 60%;
  margin: 0 auto;
}

.rootwizard-rounded > ul > li > a {
  border-radius: 4px;
}

.rootwizard-no-fade > ul > li.active ~ li {
  color: #444;
  a {
    color: #444;
    border-color: #777;
  }
}

.rootwizard-only-pager > ul > li > a {
  pointer-events: none;
  cursor: default;
}

.tab-pane-done {
  text-align: center;
  i { font-size: 10rem; color: $green; }
}

.tab-pane-shipping {
  max-width: 767px;
  margin: 0 auto;
}

.tab-pane-receipt {
  max-width: 520px;
  margin: 0 auto;
  .pane-icon i { margin-top: 35px; }
  label {
    font-size: 16px;
  }
}

@media screen and (max-width: $screen-xs-max) {
  .tab-pane-receipt {
    .pane-icon {
      text-align: center;
      margin-bottom: 1rem;
    }
    label {
      text-align: center;
      width: 100%;
    }
  }
}

.tab-pane-payment {
  max-width: 375px;
  margin: 0 auto;
}

.pane-big-icon   i { font-size: 70px; }
.pane-small-icon i { font-size: 28px; }

.tab-pane-empty-cart { font-weight: 400; }


// ########################
// ### typing animation ###
// ########################

.typed-cursor{
  opacity: 1;
  animation: blink 0.7s infinite;
}

@keyframes blink{
  0% { opacity:1; }
  50% { opacity:0; }
  100% { opacity:1; }
}
@-webkit-keyframes blink{
  0% { opacity:1; }
  50% { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes blink{
  0% { opacity:1; }
  50% { opacity:0; }
  100% { opacity:1; }
}


// ###############
// ### widgets ###
// ###############

.widget {
  padding-top: 20px;
  padding-bottom: 20px;
  li { list-style-type: none; }
  ul {
    padding-left: 0;
    margin-bottom: 0;
    li {
      margin-bottom: .75rem;
    }
  }
  .flickr-gallery {
    .span1 {
      margin: 0 3px;
      .thumbnail {
        margin-bottom: 0;
        border: 1px solid transparent;
        border-radius: 0;
      }
    }
  }
  .feedback blockquote {
    font-size: 1rem;
    font-style: italic;
    margin: 0;
  }
  .feedback .circle-img {
    height: 4em;
  }
  &:first-child {
    padding-top: 0;
  }
  &.shop-single-sidebar-item {
    padding: 0;
  }
}

.widget-carousel .slick-slide img {
  width: 100%;
}

.widget-carousel-alt .slick-slide img {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.widget-popular {
  clear: both;
  height: 80px;
  margin: 26px 0;
  position: relative;
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: #ddd;
    position: absolute;
    bottom: -13px;
  }
  &:last-child:after {
    display: none;
  }
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.widget-popular-alt {
  .widget-popular-image,
  .widget-popular-description,
  .widget-title,
  .shop-sidebar-title {
    float: right
  }
}

.widget-popular-image {
  float: left;
  height: 80px;
  width: 55px;
  position: relative;
  img {
    max-height: 80px;
    max-width: 50px;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    right: 0;
  }
}

.widget-popular-description {
  display: table;
  height: 80px;
  padding-left: 5px;
  > .inner {
    display: table-cell;
    vertical-align: middle;
    .title {
      font-family: $oswald;
    }
    .price {
      font-family: $oswald;
    }
    .rateit {
      margin-bottom: 0;
      margin-top: 2px;
    }
  }
}

.widget-title {
  text-transform: uppercase;
  margin-bottom: 20px;
  letter-spacing: 1px;
  font-size: 15px;
}

.widget-contacts {
  position: relative;
  li > i {
    width: 15px;
    text-align: center;
  }
}

.widget-archive .archive-border li {
  border-bottom: 1px solid rgba(0,0,0,.1);
  padding-bottom: .5rem;
  &:last-child { border-bottom: 0 !important; }
}

.widget-embed-video iframe { width: 100%; }

.widget-tags ul a {
  display: inline-block;
  border: 1px solid #ddd;
  padding: 5px;
  text-transform: uppercase;
  font-size: 10px;
  &:hover {
    color: #111;
    border-color: #aaa;
  }
}

.widget-search .input-text input.form-control {
  padding-right: 45px;
}

.widget-search form button,
.widget-subscribe form button {
  right: 2px;
  padding: 0 15px;
  position: relative;
  z-index: 5;
}

.widget-subscribe .input-text input.form-control {
  padding-right: 45px;
}

.widget-block-social {
  margin: -2px;
  a { margin: 2px; }
}

.widget-block-social-desc {
  width: 100%;
  a.btn {
    float: left;
    margin-right: 7px;
  }
  a.text {
    font-weight: 500;
    width: initial !important;
    display: table-cell;
    vertical-align: middle;
  }
}

.widget-block-news {
  li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 15px !important;
    padding-bottom: 15px;
    &:last-child {
      margin-bottom: 0 !important;
      border-bottom: 0 !important;
    }
  }
  .date {
    margin-top: 10px;
    color: #aaa;
  }
  .news-img {
    overflow: hidden;
    float: left;
    width: 56px;
    height: 56px;
    border-radius: 200px;
    margin: 1px .5rem 5px .5rem;
    img {
      height: inherit;
    }
  }
  .news-text {
    padding-left: 70px;
    display: block;
  }
}

.widget-block-news-alt {
  .news-img { float: right; }
  .news-text { padding-left: 0; padding-right: 65px; }
}

.widget-block-news-center {
  .news-img { float: none; display: block; margin: 10px auto; }
  .news-text { padding-left: 0; padding-right: 0; text-align: center; }
}

.widget-search .has-feedback .form-control,
.widget-subscribe .has-feedback .form-control {
  padding: 6px 12px;
}

@media screen and (min-width: $screen-md) and (max-width: $screen-md-max) {
  .widget-popular-image {
    width: 45px;
  }
}


// ####################
// ### styled icons ###
// ####################

.icon-styled {
  font-size: 2rem;
  width: 55px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  margin: 5px;
  cursor: pointer;
  transition: all .25s;
  text-shadow: 1px 1px 1px rgba(0,0,0,.25);
  &:hover { color: #000; }
}

.icon-hover-theme:hover {
  color: #fff;
}

.icon-hover-black:hover {
  background: #444;
  color: #fff;
}

.icon-tiny {
  font-size: 1rem;
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.icon-small {
  font-size: 1.5rem;
  width: 45px;
  height: 45px;
  line-height: 45px;
}

.icon-large {
  font-size: 3rem;
  width: 70px;
  height: 70px;
  line-height: 70px;
}

.icon-huge {
  font-size: 4rem;
  width: 90px;
  height: 90px;
  line-height: 90px;
}

.icon-border {
  border: 1px solid #444;
}

.icon-circle {
  border: 1px solid #444;
  border-radius: 200px;
}

.icon-rounded {
  border: 1px solid #444;
  border-radius: 4px;
}

.icon-colored {
  border: 1px solid transparent !important;
  color: #eee;
  &:hover { color: #fff; }
}

.icon-colored-alt {
  border: 1px solid transparent !important;
  color: #333;
  &:hover { color: #000 !important; }
}

.icon-dark {
  background: #444;
  color: #f0f0f0;
  border: 1px solid transparent;
}

.icon-gray {
  background: #eee;
  border: 1px solid transparent;
}


// ##############################
// ### mouse scroll animation ###
// ##############################


.mouse-scroll-anim {
  position: absolute;
  bottom: 1.5rem;
  left: 50%;
  transform: translateX(-50%);
  span {
    display: block;
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
    border-right: 2px solid #eee;
    border-bottom: 2px solid #eee;
    margin: 0 0 0 11px;
  }

  .unu, .doi, .trei {
    -webkit-animation: mouse-scroll 1s infinite;
    -moz-animation: mouse-scroll 1s infinite;
  }

  .unu {
    margin-top: 4px;
    -webkit-animation-delay: .1s;
    -moz-animation-delay: .1s;
    -webkit-animation-direction: alternate;
  }

  .doi {
    -webkit-animation-delay: .2s;
    -moz-animation-delay: .2s;
    -webkit-animation-direction: alternate;
  }

  .trei {
    -webkit-animation-delay: .3s;
    -moz-animation-delay: .3s;
    -webkit-animation-direction: alternate;
  }

  .mouse {
    height: 45px;
    width: 30px;
    border-radius: 15px;
    transform: none;
    border: 2px solid #eee;
    top: 170px;
  }

  .wheel {
    height: 10px;
    width: 2px;
    display: block;
    margin: 5px auto;
    background: #eee;
    position: relative;
    -webkit-animation: mouse-wheel 1.2s ease infinite;
    -moz-animation: mouse-wheel 1.2s ease infinite;
  }
}

@-webkit-keyframes mouse-wheel {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(15px);
  }
}

@-moz-keyframes mouse-wheel {
  0% { top: 1px; }
  50% { top: 2px; }
  100% { top: 3px;}
}

@-webkit-keyframes mouse-scroll {
  0%   { opacity: 0;}
  50%  { opacity: .5;}
  100% { opacity: 1;}
}
@-moz-keyframes mouse-scroll {
  0%   { opacity: 0; }
  50%  { opacity: .5; }
  100% { opacity: 1; }
}
@-o-keyframes mouse-scroll {
  0%   { opacity: 0; }
  50%  { opacity: .5; }
  100% { opacity: 1; }
}
@keyframes mouse-scroll {
  0%   { opacity: 0; }
  50%  { opacity: .5; }
  100% { opacity: 1; }
}


// ##############
// ### tables ###
// ##############

.thead-inverse th {
  color: #fff;
  background-color: #373a3c;
}

.thead-default th {
  color: #55595c;
  background-color: #eceeef;
}

.table-inverse {
  color: #eceeef;
  background-color: #373a3c;
}

.table-inverse.table-bordered {
  border: 0;
}

.table-inverse th,
.table-inverse td,
.table-inverse thead th {
  border-color: #55595c !important;
}

.table-reflow thead {
  float: left;
}

.table-reflow tbody {
  display: block;
  white-space: nowrap;
}

.table-reflow th,
.table-reflow td {
  border-top: 1px solid #eceeef;
  border-left: 1px solid #eceeef;
}

.table-reflow th:last-child,
.table-reflow td:last-child {
  border-right: 1px solid #eceeef;
}

.table-reflow thead:last-child tr:last-child th,
.table-reflow thead:last-child tr:last-child td,
.table-reflow tbody:last-child tr:last-child th,
.table-reflow tbody:last-child tr:last-child td,
.table-reflow tfoot:last-child tr:last-child th,
.table-reflow tfoot:last-child tr:last-child td {
  border-bottom: 1px solid #eceeef;
}

.table-reflow tr {
  float: left;
}

.table-reflow tr th,
.table-reflow tr td {
  display: block !important;
  border: 1px solid #eceeef !important;
}

.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: #ccc;
}

.pagination > li > a,
.pagination > li > span {
  color: #444;
}

.pager-new li a {
  display: inline-block;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  min-width: 100px;
  font-size: 13px;
  font-weight: 500;
  font-family: $roboto;
  text-transform: uppercase;
  span {
    font-size: 13px;
    letter-spacing: -1px;
  }
  hr {
    margin-top: 4px;
    margin-bottom: 6px;
  }
}

.pager-two-lines li a {
  padding: 4px 14px 6px 14px;
}

.faq-sidebar-img {
  margin-bottom: 10px;
  img { max-width: 95%; }
}

.lightbox {
  img { width: 100%; }
  figure { cursor: pointer; }
  .gallery-cell { display: inline-block; padding: 0;}
  figcaption {
    background: rgba(0, 0, 0, 0.45);
    button { color: #fff !important; }
  }
}

.lightbox-alt-filling .gallery-item img {
  width: 100% !important;
  height: auto;
}


.sidebar-gray-wrapper {
  overflow: hidden;
}

.sidebar-gray-left,
.sidebar-gray-right {
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: -30px;
    background-color: #fafafa;
    height: 99999px;
    width: 4000px;
    z-index: -125;
  }
  .nav-tabs > li.active > a {
    background: transparent !important;
  }
}

.sidebar-gray-left:before {
  right: 0;
  border-right: 1px solid #f3f3f3;
}
.sidebar-gray-right {
  ~ .sidebar-gray-right:before {
    border-left: 0;
  }
  &:before {
    left: 0;
    border-left: 1px solid #f3f3f3;
  }
}

.container-sitemap {
  .sitemap-section {
    font-size: 16px;
    text-transform: capitalize;
    margin-bottom: 0;
    i.fa { margin-right: 7px; }
  }
  ul[class*="-list-"] {
    list-style: none;
    padding-left: 15px;
    border-left: 1px solid;
    margin-left: 4px;
    li {
      &:before {
        border-bottom: 1px solid;
        display: block;
        position: absolute;
        width: 8px;
        content: '';
        top: 11px;
        left: -15px;
      }
      position: relative;
      i.fa { margin-right: 7px; }
      a { text-transform: capitalize; }
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .sidebar-gray-left {
    margin-right: 15px;
    margin-left: -15px;
    &:before { top: -500px; }
  }
  .sidebar-gray-right {
    margin-right: -15px;
    margin-left: 15px;
    &:before { top: -500px; }
  }
  .clients-grid-6-col .clients-grid-element:nth-child(6n+6):before { border-right: none; }
  .clients-grid-5-col .clients-grid-element:nth-child(5n+5):before { border-right: none; }
  .clients-grid-4-col .clients-grid-element:nth-child(4n+4):before { border-right: none; }
  .clients-grid-3-col .clients-grid-element:nth-child(3n+3):before { border-right: none; }
  .clients-grid-2-col .clients-grid-element:nth-child(2n+2):before { border-right: none; }
}

@media screen and (max-width: $screen-xs-max) {
  .sidebar-gray-left {
    margin-top: 30px;
    width: 91.66667%;
    padding-right: calc(8.33333% + 15px);
    &:before { border-right: 0; }
  }
  .sidebar-gray-right {
    margin-top: 30px;
    width: 91.66667%;
    padding-left: calc(8.33333% + 15px);
    margin-left: 0;
    &:before { border-left: 0; }
  }
}